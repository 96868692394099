import React, { useState, useEffect } from 'react'
import { normalizeString } from 'views/ViewPromo/Utils'
import { Table, Head, Body, Row, Cell } from '../../../../Components/ViewTable'
import { InnerTable } from '../../../ViewConditions/Components'
import { numberFormatter } from 'utils/numberFormatter'
import { ViewLoader, ViewTag } from '../../../../Components'
import { PaymentsContent } from './Components'
import style from './Discount.module.css'
import { productEntities } from 'components/PromoWizard/Steps/ConditionsForm/variables'
import { getEntities } from 'api/EntitiesAPI'
import AttributesContent from './Components/AttributesContent /AttributesContent'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const Discount = ({ data, className, value }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(undefined)
  const { numberFormat } = numberFormatter()
  const [info, setInfo] = useState(data)

  const changeOpen = id => {
    setOpen(prev => (prev === id ? undefined : id))
  }

  const fetchProducts = async (attributes, endpoint) => {
    if (!attributes.length || !endpoint) return null

    const ids = attributes.join(',')
    try {
      const response = await getEntities(`${endpoint}/ids?ids=${ids}`)
      return response.data
    } catch (error) {
      console.error('Error fetching products:', error)
      return null
    }
  }

  const updateInfo = async () => {
    setIsLoading(true)

    const updatedInfo = await Promise.all(
      data.map(async item => {
        if (item.generate_credit_note) {
          const entityIds = item.action_line_entity.map(entity => entity.entity_id)
          const endpoint = productEntities.find(({ value }) => value === 'PRODUCT_ID')?.endpoint || ''

          const fetchedData = await fetchProducts(entityIds, endpoint)

          if (fetchedData) {
            const updatedActionLineEntity = item.action_line_entity.map(entity => {
              const matchedEntity = fetchedData.find(attr => attr.id === entity.entity_id)
              return {
                ...entity,
                entity_type_id: matchedEntity?.description || '',
              }
            })

            return { ...item, action_line_entity: updatedActionLineEntity }
          }
        }

        return item
      })
    )

    setInfo(updatedInfo)
    setIsLoading(false)
  }

  useEffect(() => {
    if (data.length) {
      updateInfo()
    }
  }, [data])


  return (
    <Table
      view={'actions'} title={value} className={className} search={true}
      renderSearchValue={(searchValue) => {
        const valueFiltered = info.filter(({ discount, max_discount, action_line_entity }) => String(discount).toLowerCase().includes(searchValue.toLowerCase())
          ||
          String(max_discount).toLowerCase().includes(searchValue.toLowerCase())
          ||
          `${action_line_entity[0]?.entity_id} - ${action_line_entity[0]?.entity_type_id}`.toLowerCase().includes(normalizeString(searchValue).toLowerCase()))
        return (
          <>
            <Head
              view={`actions.${value}`}
              columns={[
                { value: 'apply' },
                { value: 'payment_method' },
                { value: 'attributes' },
                { value: 'credit_note' },
                { value: 'ceiling' },
                { value: 'value' },
              ]}
            />
            <Body>
              {valueFiltered.length ? valueFiltered.map(
                ({
                  id,
                  quantity,
                  payments,
                  discount,
                  attributes,
                  percentage,
                  max_discount,
                  lower_unit_price,
                  action_line_entity,
                  generate_credit_note,
                }) => {
                  let description = ''
                  if (quantity === 0 && lower_unit_price === false) {
                    description = 'Todas las UNID'
                  } else if (lower_unit_price) {
                    description = 'UNID de menor precio'
                  } else {
                    description = `Sobre ${quantity} UNID`
                  }

                  return (
                    <React.Fragment key={id}>
                      <Row
                        withArrow={true}
                        onClickArrow={() => changeOpen(id)}
                        className={open === id ? style.isOpen : ''}>
                        <Cell bold>{description}</Cell>
                        <Cell>
                          <ViewTag
                            color={'#E4FDFA'}
                            fontColor={'#348888'}
                            borderColor={'#348888'}
                            label={`${payments.length !== 0 ? payments.length : ''} ${payments.length !== 0
                              ? payments.length !== 1
                                ? 'Seleccionados'
                                : 'Seleccionado'
                              : 'Todos los medios de pago'
                              }`}
                          />
                        </Cell>
                        <Cell>
                          <ViewTag
                            color={'#E4FDFA'}
                            fontColor={'#348888'}
                            borderColor={'#348888'}
                            label={`${attributes.length} ${attributes.length !== 1 ? 'Seleccionados' : 'Seleccionado'}`}
                          />
                        </Cell>
                        <Cell bold>
                          {generate_credit_note
                            ? `${action_line_entity[0]?.entity_id} - ${action_line_entity[0]?.entity_type_id}`
                            : '-'}
                        </Cell>
                        <Cell bold>{`$${String(numberFormat.format(parseFloat(max_discount).toFixed(2)))}`}</Cell>
                        <Cell bold>
                        {String(`${percentage ? '' : '$'}${numberFormat.format(parseFloat(discount).toFixed(2))}${percentage ? '%' : ''}`)}
                        </Cell>
                      </Row>
                      <InnerTable colSpan={7} itemOpen={open === id} name={'actions.discount'}>
                        {payments.length > 0 &&
                          <>
                            <Row className={style['details-header']}>
                              <Cell key={`header-atributos`} colSpan={7} >
                                MEDIOS DE PAGO
                              </Cell>
                            </Row>
                            <PaymentsContent payments={payments} open={open === id} page={"discount"} />

                          </>
                        }
                        <Row className={style['details-header']}>
                          <Cell key={`header-atributos`} colSpan={7}>
                            ATRIBUTOS
                          </Cell>
                        </Row>
                        <AttributesContent productsInfo={attributes} open={open === id} />
                      </InnerTable>
                    </React.Fragment>
                  )
                }
              ) : 
                <Row>
                  <Cell colSpan={6}>
                    <EmptyState />
                  </Cell>

                </Row>
              }
            </Body>
          </>
        )
      }}>
    </Table>

  )
}

export default Discount

