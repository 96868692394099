// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewTable_container__2a8dG {\n  width: 100%;\n  background: #fff;\n  border-radius: 12px;\n  box-shadow: 0px 0px 4px 0px rgba(0, 34, 171, 0.15);\n}\n\n.ViewTable_container-header__19PI6 {\n  width: 100%;\n  display: flex;\n  min-height: 44px;\n  padding: 32px 24px 12px 24px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.ViewTable_table__2Vg9d {\n  width: 100%;\n  table-layout: fixed;\n  border-collapse: collapse;\n}\n\n.ViewTable_entity__3Gh6E {\n  font: normal 600 16px/131.25% 'Inter';\n  color: var(--Gris-100, #68688c);\n  margin: unset;\n}\n\n.ViewTable_entity__3Gh6E > strong {\n  font: normal 700 16px/131.25% 'Inter';\n  color: var(--Naranja-100, #fa7f08);\n  padding-right: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ViewTable_container__2a8dG",
	"container-header": "ViewTable_container-header__19PI6",
	"table": "ViewTable_table__2Vg9d",
	"entity": "ViewTable_entity__3Gh6E"
};
module.exports = exports;
