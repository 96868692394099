import { useTranslation } from 'react-i18next'
import React from 'react'
import { ViewExplain } from 'views/ViewPromo/Components'
import style from './Head.module.css'
import { Row } from '..'

const Head = ({ columns, view }) => {
  const { t } = useTranslation('viewPromo')

  return (
    <thead className={style.thead}>
      <Row className={style.tr}>
        {columns.map(({ value, className, viewExplain = undefined}, idx) => (
          <th className={`${style.th} ${className ?? ''} ${viewExplain ? style.viewExplainHead : ""}`} key={`table-head-${view}-${idx}`}>
            {typeof value === 'string' ? <span className={style.span}>{value ? t(`${view}.table.${value}`) : ""}</span> : value}
            {viewExplain ?
            <ViewExplain
                  cell={false}
                  title={t('actions.set_price.tooltip.header.payment')}
                  desc={t('actions.set_price.tooltip.body.payment')}
                />
            : <></>}
          </th>
        ))}
      </Row>
    </thead>
  )
}

export default Head
