// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewExplain_cell__BCb0J {\n  padding-inline: unset !important;\n}\n\n.ViewExplain_container__2mv5i {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ViewExplain_icon__3C-ZN {\n  position: relative;\n  width: 24px;\n  height: 24px;\n  cursor: pointer;\n}\n\n.ViewExplain_tooltip__2BnzW {\n  position: absolute;\n  bottom: 125%;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: rgba(247, 247, 255, 1);\n  border-radius: 10px;\n  padding: 10px 15px;\n  -webkit-filter: drop-shadow(0px 2px 5px rgba(104, 104, 140, 0.17)) drop-shadow(1px 1px 1px rgba(104, 104, 140, 0.04));\n          filter: drop-shadow(0px 2px 5px rgba(104, 104, 140, 0.17)) drop-shadow(1px 1px 1px rgba(104, 104, 140, 0.04));\n  text-align: start;\n  white-space: nowrap;\n  z-index: 10;\n  opacity: 0;\n  visibility: hidden;\n  transition: ease-out 0.3s;\n}\n\n.ViewExplain_tooltip__2BnzW > h4 {\n  margin: unset;\n  margin-bottom: 8px;\n  font: normal 500 14px/128.571% Inter;\n  color: var(--Gris-200, #303048);\n  letter-spacing: 0.28px;\n}\n\n.ViewExplain_tooltip__2BnzW > p {\n  font: normal 400 12px/133.333% Inter;\n  color: var(--Gris-100, #68688c);\n}\n\n.ViewExplain_tooltip__2BnzW::after {\n  content: '';\n  position: absolute;\n  top: 98%;\n  left: 50%;\n  transform: translateX(-65%);\n  border-width: 10px;\n  border-style: solid;\n  border-color: rgba(247, 247, 255, 1) transparent transparent transparent;\n}\n\n.ViewExplain_icon__3C-ZN:hover .ViewExplain_tooltip__2BnzW {\n  opacity: 1;\n  visibility: visible;\n  bottom: 135%;\n}\n", ""]);
// Exports
exports.locals = {
	"cell": "ViewExplain_cell__BCb0J",
	"container": "ViewExplain_container__2mv5i",
	"icon": "ViewExplain_icon__3C-ZN",
	"tooltip": "ViewExplain_tooltip__2BnzW"
};
module.exports = exports;
