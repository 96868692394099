// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductsTable_isOpen__18o4a > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.ProductsTable_aling-right__1akvj {\n  text-align: right;\n}\n\n.ProductsTable_innerTableProducts__32R2M tr th:last-of-type{\n  width: 80%;\n}", ""]);
// Exports
exports.locals = {
	"isOpen": "ProductsTable_isOpen__18o4a",
	"aling-right": "ProductsTable_aling-right__1akvj",
	"innerTableProducts": "ProductsTable_innerTableProducts__32R2M"
};
module.exports = exports;
