// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DetailedViewCard_card__3eD4l {\n  border-radius: 8px;\n  border: 1px solid var(--Gris-20, #ccd1e4);\n  background: var(--Blanco, #fff);\n  padding: 12px;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "DetailedViewCard_card__3eD4l"
};
module.exports = exports;
