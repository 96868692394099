import React from 'react'

import style from './ViewHeader.module.css'
import { ViewCard } from '..'
import svg from './Assets'

const ViewHeader = ({ value, label, className }) => {
  return (
    <ViewCard className={`${style.card} ${className ?? ''}`}>
      <div className={style.svg}>
        <img src={svg[value]} alt={label} />
      </div>
      <h3>{label}</h3>
    </ViewCard>
  )
}

export default ViewHeader
