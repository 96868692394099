import React from 'react'

import style from './loading.module.css'

const Loading = ({ view = false }) => {
  return (
    <div className={`${style.loadingContainer} ${view ? style.view : ''}`}>
      <div className={style.loading}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>Cargando...</span>
    </div>
  )
}

export default Loading
