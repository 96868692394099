// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewTag_tag__2mjLs {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  position: relative;\n  padding: 4px 12px;\n}\n\n.ViewTag_tag__2mjLs > span {\n  position: relative;\n  font: normal 500 13px/130% 'Inter';\n  color: var(--font-color, var(--Gris-100, #68688c));\n  z-index: 1;\n  max-width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.ViewTag_tag__2mjLs > div {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 30px;\n  background: var(--tag-color, var(--primary-color));\n  border: 1px solid var(--border-color, var(--Gris-20, #ccd1e4));\n}\n\n.ViewTag_unfilled__1W7AX > div {\n  background: transparent;\n}\n\n.ViewTag_unfilled__1W7AX > span {\n  color: var(--font-color, var(--tag-color, var(--primary-color)));\n}\n", ""]);
// Exports
exports.locals = {
	"tag": "ViewTag_tag__2mjLs",
	"unfilled": "ViewTag_unfilled__1W7AX"
};
module.exports = exports;
