import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { normalizeString } from 'views/ViewPromo/Utils'
import { productEntities } from '../../../../../../components/PromoWizard/Steps/ConditionsForm/variables'
import { Table, Head, Body, Row, Cell } from '../../../../Components/ViewTable'
import { ViewTag, ViewLoader, ViewExplain } from '../../../../Components'
import { InnerTable } from '../../../ViewConditions/Components'
import { PaymentsContent } from '../Discount/Components'
import numberFormatter from 'utils/numberFormatter'
import { getEntities } from 'api/EntitiesAPI'
import style from './SetPrice.module.css'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const SetPrice = ({ data, className, value }) => {
  const { t } = useTranslation('viewPromo')
  const [loadingAttributes, setLoadingAttributes] = useState(true)
  const [transformedData, setTransformedData] = useState(data)
  const [open, setOpen] = useState(undefined)

  const { numberFormat } = numberFormatter()

  const changeOpen = id => {
    setOpen(prev => (prev === id ? undefined : id))
  }

  const formatToParams = () => {
    return data
      .map(({ attributes }) => ({
        endpoint: productEntities.find(({ value }) => value === attributes[0]?.entity)?.endpoint,
        ids: attributes.flatMap(attr => attr.ids),
      }))
      .reduce((acc, { endpoint, ids }) => {
        const existing = acc.find(item => item.endpoint === endpoint)
        if (existing) {
          existing.ids = Array.from(new Set([...existing.ids, ...ids]))
        } else {
          acc.push({ endpoint, ids })
        }
        return acc
      }, [])
  }

  const fetchProducts = async params => {
    if (!params || params.length === 0) return

    const { endpoint, ids } = params[0]

    try {
      const { data: content } = await getEntities(`${endpoint}/ids?ids=${ids.join(',')}`)
      if (!Array.isArray(content) || content.length === 0) {
        console.warn('No content fetched')
        return
      }

      const contentMap = Object.fromEntries(content.map(({ id, description }) => [id, `${id} - ${description}`]))

      const transformedData = data.map(({ attributes, ...rest }) => ({
        ...rest,
        attributes: attributes.map(({ entity, ids }) => ({
          entity,
          ids,
          description: ids.map(id => contentMap[id] || null),
        })),
      }))

      setTransformedData(transformedData)
      setLoadingAttributes(false)
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }

  useEffect(() => {
    if (data) {
      fetchProducts(formatToParams())
    }
  }, [data])

  return (
    transformedData.length !== 0 && (
      <Table view={'actions'} title={value} className={`${className} ${style.setPriceTable}`} search={true}
        renderSearchValue={(searchValue) => {
          const valueFiltered = transformedData.filter(({ attributes, discount }) => normalizeString(attributes[0].description?.[0] || '').toLowerCase().includes(searchValue.toLowerCase()) ||
          `$${numberFormat.format(parseFloat(discount).toFixed(2))}`.toLowerCase().includes(searchValue.toLowerCase())
          )
          return(
            <>
                    <Head
          view={`actions.${value}`}
          columns={[
            { value: 'article' },
            { value: '' },
            { value: '' },
            { value: 'discount', className: style.discount },
            { value: 'payment_method', viewExplain: {
              cell: false, 
              title:t('actions.set_price.tooltip.header.payment'), 
              desc:t('actions.set_price.tooltip.body.payment')
            }},
            {
              value: ""
            },
            { value: 'final_price' },
          ]}
        />
        <Body>
          {valueFiltered.length ? valueFiltered.map(({ id, attributes, payments, discount, quantity }) => (
            <Fragment key={id}>
              {loadingAttributes ? (
                <Row>
                  <Cell>
                    <ViewLoader small />
                  </Cell>
                </Row>
              ) : (
                <Row
                  withArrow={payments.length !== 0}
                  onClickArrow={() => changeOpen(id)}
                  className={open === id ? style.isOpen : ''}>
                  <Cell colSpan={3} bold>{`${attributes.map(attr => attr.description)}`}</Cell>
                  <ViewExplain
                    title={t('actions.set_price.tooltip.header.discount')}
                    desc={
                      quantity !== 0
                        ? quantity > 1
                          ? t('actions.set_price.tooltip.body.start') +
                            quantity +
                            t('actions.set_price.tooltip.body.end')
                          : t('actions.set_price.tooltip.body-single')
                        : t('actions.set_price.tooltip.body-no-quantity')
                    }
                  />
                  <Cell>
                    <ViewTag
                      color={'#E4FDFA'}
                      fontColor={'#348888'}
                      borderColor={'#348888'}
                      label={`${payments.length !== 0 ? payments.length : ''} ${
                        payments.length !== 0
                          ? payments.length !== 1
                            ? 'Seleccionados'
                            : 'Seleccionado'
                          : 'Todos los medios de pago'
                      }`}
                    />
                  </Cell>
                  <Cell></Cell>
                  <Cell>${numberFormat.format(parseFloat(discount).toFixed(2))}</Cell>
                </Row>
              )}
              <InnerTable addLabel={false} itemOpen={open === id} colSpan={8} name={'actions.discount'} className={style.setPriceInner}>
                <PaymentsContent payments={payments} open={open === id} page="set-price" columns={[1, 2]}/>
              </InnerTable>
            </Fragment>
          ))
          :
          <Row>
          <Cell className={style.emptyStateCell} colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px'}}>
            <EmptyState />
          </Cell>
        </Row>
        }
        </Body>
            </>
          )
        }}
      >

      </Table>
    )
  )
}

export default SetPrice
