import React, { useEffect, useState } from 'react'

import { ClientsTable, ProductsTable, ReceiptTable } from './Components'
import style from './ViewConditions.module.css'

import {
  ruleTypes,
  paymentMethodTypesArgExclusive,
} from '../../../../components/PromoWizard/Steps/ConditionsForm/variables'

const ViewConditions = ({ values }) => {
  const [data, setData] = useState({
    Cliente: [],
    Producto: [],
    Comprobante: [],
  })

  const groupEntities = data => {
    const grouped = {
      Cliente: [],
      Producto: [],
      Comprobante: [],
    }
    data.forEach(item => {
      ruleTypes.forEach(rule => {
        if (rule.entities.some(entity => entity.value === item.entity)) {
          grouped[rule.name].push(item)
        }
      })
      paymentMethodTypesArgExclusive.map(rule => {
        if (rule.value === item.entity) {
          grouped['Comprobante'].push(item)
        }
      })
    })

    return setData(grouped)
  }

  useEffect(() => {
    groupEntities(values)
  }, [])

  return (
    <div className={style.container}>
      <ClientsTable
        clientInfo={data.Cliente}
        className={data.Cliente?.length !== 0 && data.Comprobante?.length && style['fifty-percent']}
      />
      <ReceiptTable
        values={data.Comprobante}
        className={data.Cliente?.length !== 0 && data.Comprobante?.length && style['fifty-percent']}
      />
      <ProductsTable productsInfo={data.Producto} />
    </div>
  )
}

export default ViewConditions
