import actions from './actions.svg'
import conditions from './conditions.svg'
import config from './config.svg'
import when from './when.svg'
import where from './where.svg'

const svg = {
  actions,
  conditions,
  config,
  when,
  where,
}

export default svg
