// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewPromo_section__3Mmep {\n  width: 100%;\n  padding: 6.5rem 2rem 2rem 2rem;\n  grid-gap: 0.75rem;\n  gap: 0.75rem;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.ViewPromo_card__1QybS {\n  width: 100%;\n}\n\n.ViewPromo_panel__3hOqV {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0.25rem;\n  gap: 0.25rem;\n  margin-top: 58px;\n}\n\n.ViewPromo_promoDetailHeader__2ISEX{\n  height: 58px;\n  background: var(--Blanco, #FFF);\n  border-radius: 16px 16px 6px 6px;\n  display: flex;\n  padding: 19px 24px;\n  align-items: center;\n  grid-gap: 2ch;\n  gap: 2ch;\n  box-shadow: 1px 1px 1px 0px rgba(104, 104, 140, 0.04), 0px 2px 5px 0px rgba(104, 104, 140, 0.17);\n  position: fixed;\n  top: 101px;\n  z-index: 5;\n  left: 2rem;\n  right: 2.3rem;\n}\n\n.ViewPromo_promoDetailHeaderTop__32Bet{\n  background: linear-gradient(180deg, #f7f7ff 105%, transparent);\n  position: fixed;\n  left: 0;\n  right: 0;\n  height: 9rem;\n  top: 1rem;\n  z-index: 5;\n}\n\n.ViewPromo_promoDetailHeader__2ISEX i {\n  cursor: pointer;\n}\n\n\n.ViewPromo_promoDetailHeader__2ISEX > span{\n  font-weight: 600;\n}\n\n.ViewPromo_promoDetailHeader__2ISEX > span > span{\n  color: var(--Gris-100, #68688C);  \n  font-weight: 600;\n}\n\n@media (min-width: 1300px) {\n  .ViewPromo_fifty-percent__S3LxQ {\n    max-width: calc(50% - 0.75rem);\n  }\n}\n\n\n\n.ViewPromo_containerWheniWhere__22rZn{\n      width: 100%;\n    grid-gap: 0.75rem;\n    grid-gap: 0.75rem;\n    gap: 0.75rem;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-top: 0.5rem;\n}", ""]);
// Exports
exports.locals = {
	"section": "ViewPromo_section__3Mmep",
	"card": "ViewPromo_card__1QybS",
	"panel": "ViewPromo_panel__3hOqV",
	"promoDetailHeader": "ViewPromo_promoDetailHeader__2ISEX",
	"promoDetailHeaderTop": "ViewPromo_promoDetailHeaderTop__32Bet",
	"fifty-percent": "ViewPromo_fifty-percent__S3LxQ",
	"containerWheniWhere": "ViewPromo_containerWheniWhere__22rZn"
};
module.exports = exports;
