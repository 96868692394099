import React, { useState, useEffect, useLayoutEffect } from 'react'

import {
  paymentMethodTypes,
  paymentMethodTypesArgExclusive,
} from '../../../../../../components/PromoWizard/Steps/ConditionsForm/variables'

import { Table, Head, Body, Row, Cell } from '../../../../Components/ViewTable'
import { ViewTag } from '../../../../Components'
import { getEntities } from 'api/EntitiesAPI'
import style from './ReceiptTable.module.css'
import { InnerTable } from '..'
import { normalizeString } from 'views/ViewPromo/Utils'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const ReceiptTable = ({ values, className = '' }) => {
  const [paymenth, setPaymenth] = useState([])
  const [amount, setAmount] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = param => {
    setIsOpen(param !== undefined ? param : !isOpen)
  }

  const getColumns = param => {
    switch (param) {
      case 'CASH':
        return ['cash']
      case 'CREDIT_CARD':
        return ['card', 'fees-selected']
      case 'INTERDEPOSIT':
        return ['interdeposit']
      case 'PAGO_QR':
        return ['qr']
    }
  }

  const fetchPaymenth = async param => {
    if (!param && param.length === 0) return

    const promise = param.map(item => {
      return getEntities(item.endpoint)
    })

    Promise.all(promise)
      .then(res => {
        res.map(({ data: { content } }) => {
          setPaymenth(
            param.map(({ entity_details, ...item }) => {
              return {
                ...item,
                entity_details: entity_details.map(({ entity_id, detail, ...restOfDetails }) => {
                  return {
                    entity_id: entity_id,
                    detail: { label: content.find(c => c.id === entity_id)?.description, fees: detail?.fees },
                    ...restOfDetails,
                  }
                }),
              }
            })
          )
          setLoading(false)
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    setAmount(values.find(val => val.entity === 'AMOUNT'))

    const temp_paymenth = values.filter(val => val.entity !== 'AMOUNT')

    const formatPaymenth = temp_paymenth.map(item => {
      const paymenthDetails = paymentMethodTypesArgExclusive.find(p => p.value === item.entity)

      return { ...item, columns: getColumns(item.entity), endpoint: paymenthDetails.endpoint }
    })

    setLoading(true)
    fetchPaymenth(formatPaymenth)
  }, [values])

  return (
    values?.length !== 0 && (
      <Table className={className} entity={'Comprobante'} search={true}
        renderSearchValue={(searchValue) => {
          const filteredValues = paymenth.flatMap(pay => pay.entity_details).filter( det => det.detail.label.toLowerCase().includes(normalizeString(searchValue).toLowerCase()))
          if(searchValue && !isOpen){
            setIsOpen(true)
          }
          return(
            <>
                    <Head
          view={'conditions.receipt'}
          columns={[{ value: 'attributes' }, { value: 'conditions-value' }, { value: 'value' }]}
        />
        <Body>
          {paymenth?.map(({ id, entity, entity_details, excluded, exclusive, columns }) => (
            <React.Fragment key={id}>
              <Row
                withArrow={entity_details?.length !== 0}
                onClickArrow={toggleOpen}
                className={isOpen ? style.isOpen : ''}>
                <Cell bold>Medios de pago</Cell>
                <Cell>
                  <ViewTag
                    label={`${
                      entity_details.length !== 0 ? `${entity_details.length} seleccionados` : 'Sin medios de pago'
                    }`}
                    fontColor={'#FF7D00'}
                    borderColor={'#FF7D00'}
                    color={'rgba(254, 248, 242, 1)'}
                  />
                </Cell>
                <Cell className={style.colored}>
                  {`${exclusive ? 'Exclusivo para esta promoción.' : excluded ? 'Excluido para esta promoción' : '-'} `}
                </Cell>
              </Row>
              <InnerTable colSpan={4} itemOpen={isOpen} name={'conditions.receipt'} columns={columns} loading={loading}>
                {
                  filteredValues.length ?
                  entity === 'CREDIT_CARD' ? (
                    filteredValues.map(card => (
                      <Row key={card.entity_id}>
                        <Cell>
                          <ViewTag
                            className={style['card-tag']}
                            label={card.detail.label}
                            fontColor={'#68688C'}
                            color={'transparent'}
                            borderColor={'#A1A9C7'}
                          />
                        </Cell>
                        <Cell>
                          <div className={style.fees}>
                            {card.detail.fees.map((fee, idx) => (
                              <ViewTag
                                key={idx}
                                label={`${fee}`}
                                fontColor={'#68688C'}
                                color={'transparent'}
                                borderColor={'#A1A9C7'}
                              />
                            ))}
                          </div>
                        </Cell>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <Cell>
                        <div className={style.fees}>
                          {filteredValues.map(({ detail }, idx) => (
                            <ViewTag
                              key={idx}
                              fontColor={'#68688C'}
                              color={'transparent'}
                              borderColor={'#A1A9C7'}
                              label={detail.label}
                              className={style['card-tag']}
                            />
                          ))}
                        </div>
                      </Cell>
                    </Row>
                  )
                  :
                  <Row>
                  <Cell colSpan={1} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                    <EmptyState />
                  </Cell>
                </Row>
                }

              </InnerTable>
            </React.Fragment>
          ))}
          {amount && (
            <Row>
              <Cell bold>Total de comprobante</Cell>
              <Cell>
                {amount?.details && amount.details.calculation_type === 'RANGE_AMOUNT'
                  ? 'Entre rangos'
                  : 'Mayor o igual'}
              </Cell>
              <Cell>
                {amount?.details && amount.details.calculation_type === 'RANGE_AMOUNT'
                  ? `${amount.details.range_from} a ${amount.details.range_to}`
                  : amount.details.range_from}
              </Cell>
            </Row>
          )}
        </Body>
            </>
          )
        }}
      >

      </Table>
    )
  )
}

export default ReceiptTable
