import style from "./emptyState.module.css"
import emptyIcon from "../../../../components/PromotionsTable/assets/emptySearch.svg"
import { useTranslation } from "react-i18next"
import React from "react"

const EmptyState = () => {

    const {t} = useTranslation("viewPromo")

    return(
        <div className={style.emptyState}>
            <img src={emptyIcon} alt="" />
            <span>{t('empty')}.</span>
        </div>
    )
}

export default EmptyState