import React, { useState, useEffect, Fragment } from 'react'
import { Body, Head, Table, Row, Cell } from '../../Components/ViewTable'
import { InnerTable } from '../ViewConditions/Components'
import { ViewTag } from '../../Components'
import style from './ViewWhere.module.css'
import { getEntities } from 'api/EntitiesAPI'
import { Loading } from 'components/PromoWizard/Steps/ActionsForm/components'
import { ARG_COLUMNS, EVERYWHERE, PERU_COLUMNS, findCompanies, getMatrix, removeRepeated, getColAmount } from './utils'
import i18n from 'i18n'


const ViewWhere = ({ values, className = '' }) => {
  const [matrix, setMatrix] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(null)
  const [parents, setParents] = useState(null)
  const [origin, setOrigin] = useState(null)

  const IS_EVERYWHERE = values.includes(EVERYWHERE)
  const CURRENT_LANGUAGE = i18n.language
  
  const toggleOpen = param => {
    setIsOpen(cur => cur === param ? null : param)
  }
  
  useEffect(() => {
    getEntities('/origin')
    .then(({ data }) => {
        setParents(new Set(findCompanies(data, values)))
        setMatrix(getMatrix(data, values, CURRENT_LANGUAGE?.includes('pe')))
        setOrigin(data)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
    }, [])

  if (loading || !parents || !matrix) {
    return <Loading />
  }


  

  return (
    values?.length !== 0 && (
      <Table className={` ${className}  ${style['heightContainerWheniWhere']} ${style["viewWhereTable"]}`} search={false}>
        <Head
          view={'where'}
          columns={[{ value: 'company' }, {
            className: style.options,
            value: (
              <ViewTag
                label={'Opciones seleccionadas'}
                color={'#E4FDFA'}
                fontColor={'#348888'}
                borderColor={'#348888'}
              />
            ),
          },]}
        />
        <Body>
          {
            Array.from(parents)?.map((company) => {
              return (
                <Fragment key={company.id}>
                  <Row
                    onClickArrow={() => toggleOpen(company.id)}
                    withArrow={!IS_EVERYWHERE && !values.includes(company.id)}>
                    <Cell className={style.companyLabel}>
                      {company.label}
                    </Cell>
                    <Cell>
                    </Cell>
                  </Row>
                  {
                    !IS_EVERYWHERE && origin !== null && isOpen === company.id &&
                    <WhereInnerTable
                      matrix={removeRepeated(matrix.filter(line => line.includes(company.label)))}
                      colAmount={isOpen === company.id ? getColAmount(values, matrix.filter(line => line.includes(company.label)), origin, CURRENT_LANGUAGE?.includes('pe')) : []}
                      company={company}
                      isOpen={isOpen} />
                  }

                </Fragment>
              )
            })
          }

        </Body>
      </Table>
    )
  )
}


export default ViewWhere


const WhereInnerTable = ({ matrix, isOpen, company, colAmount }) => {

  const CURRENT_LANGUAGE = i18n.language


  return (
    <InnerTable
      className={style.amountColHead}
      colSpan={3}
      name={'where'}
      itemOpen={isOpen === company.id}
      columns={CURRENT_LANGUAGE?.includes('pe') ? PERU_COLUMNS : ARG_COLUMNS}
      colAmount={colAmount}
    >
      {
        matrix.map((col, idx) => <InnerTableRows col={col} key={company.id + idx} />)
      }
    </InnerTable>
  )
}

const InnerTableRows = ({ col }) => {

  const CURRENT_LANGUAGE = i18n.language


  if (!CURRENT_LANGUAGE?.includes('pe')) {
    return (
      <Row>
        <Cell>
          <span>{col[2] || ""}</span>
        </Cell>
        <Cell>
          <span>{col[2] !== 'Todos' && col[3] ? col[3] : ""}</span>
        </Cell>
      </Row>
    )
  }

  return (
    <Row>
      <Cell>
        <span>{col[2] || ""}</span>
      </Cell>
      <Cell>
        <span>{col[2] !== 'Todos' && col[3] ? col[3] : ""}</span>
      </Cell>
      <Cell>
        <span>{col[3] !== 'Todos' && col[4] ? col[4] : ""}</span>
      </Cell>
      <Cell>
        <span>{col[4] !== 'Todos' && col[5] ? col[5] : ""}</span>
      </Cell>
      <Cell>
        <span>{col[5] !== 'Todos' && col[6] ? col[6] : ""}</span>
      </Cell>
    </Row>
  )
}