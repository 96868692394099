// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loading_loadingContainer__1GLwB {\n  background: var(--white);\n  width: 100%;\n  height: -webkit-fit-content;\n  height: -moz-fit-content;\n  height: fit-content;\n  display: flex;\n  grid-gap: 2rem;\n  gap: 2rem;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-block: 2rem;\n}\n\n.loading_loading__2QJnK {\n  display: flex;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.loading_loading__2QJnK > div {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background: var(--primary-color);\n}\n\n.loading_loadingContainer__1GLwB > span {\n  font: normal 600 20px/19px Inter;\n  color: var(--gris-100);\n}\n\n.loading_loading__2QJnK > div:nth-child(1) {\n  animation: loading_animationLoading__2wfPh infinite 1.2s ease-out;\n}\n\n.loading_loading__2QJnK > div:nth-child(2) {\n  animation: loading_animationLoading__2wfPh infinite 1.2s 0.4s ease-out;\n}\n\n.loading_loading__2QJnK > div:nth-child(3) {\n  animation: loading_animationLoading__2wfPh infinite 1.2s 0.8s ease-out;\n}\n\n@keyframes loading_animationLoading__2wfPh {\n  0% {\n    scale: 1;\n  }\n  50% {\n    scale: 0.8;\n  }\n  100% {\n    scale: 1;\n  }\n}\n\n.loading_view__3M1or {\n  height: calc(100dvh - 9rem);\n  background: transparent;\n}\n", ""]);
// Exports
exports.locals = {
	"loadingContainer": "loading_loadingContainer__1GLwB",
	"loading": "loading_loading__2QJnK",
	"animationLoading": "loading_animationLoading__2wfPh",
	"view": "loading_view__3M1or"
};
module.exports = exports;
