// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewHeader_card__AMCAt {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  grid-gap: 12px;\n  gap: 12px;\n}\n\n.ViewHeader_svg__1p_7j {\n  width: 20px;\n  height: 20px;\n}\n\n.ViewHeader_svg__1p_7j > img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.ViewHeader_card__AMCAt > h3 {\n  font: normal 600 16px / normal Inter;\n  color: #348888;\n  margin-bottom: unset;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "ViewHeader_card__AMCAt",
	"svg": "ViewHeader_svg__1p_7j"
};
module.exports = exports;
