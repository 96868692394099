import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from '../../../../Components/ViewTable'
import { ViewLoader } from '../../../../Components'
import style from './InnerTable.module.css'

const InnerTable = ({ itemOpen, colSpan, name, columns = [], children, loading, colAmount = [], className = '', addLabel = true }) => {
  const [isLeaving, setIsLeaving] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const { t } = useTranslation('viewPromo')

  useEffect(() => {
    if (itemOpen === false) {
      setIsLeaving(true)
      setTimeout(() => {
        setIsLeaving(false)
        setIsOpen(false)
      }, 300)
    } else {
      setIsLeaving(false)
      setIsOpen(true)
    }
  }, [itemOpen])

  if (!isOpen) {
    return null
  }

  return (
    <tr className={style['row-container']}>
      <td colSpan={colSpan}>
        <div className={`${style['inner-table-container']} ${isLeaving ? style.isLeaving : ''}`}>
          {loading ? (
            <ViewLoader />
          ) : (
            <table className={style.table}>
              {columns.length !== 0 && (
                <thead className={className}>
                  <Row className={style.row}>
                    {columns.map((col, idx) => (
                      <th key={idx}>
                        <span>{ addLabel ? t(`${name}.innerTable.${col}`) : ''}
                          {colAmount.length && colAmount[idx]  ? <span>{colAmount[idx]}</span> : null}
                        </span>
                      </th>
                    ))}
                  </Row>
                </thead>
              )}
              <tbody className={style.body}>{children}</tbody>
            </table>
          )}
        </div>
      </td>
    </tr>
  )
}

export default InnerTable
