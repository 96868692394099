// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AttributesContent_details-container-row-header__GVlGW > td {\n  max-width: 100%;\n  text-align: start;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font: normal 500 14px/150% Inter;\n  text-transform: uppercase;\n  color: #68688c;\n}\n\n.AttributesContent_details-container-row-header__GVlGW {\n  border-block: 1px solid #ccd1e4 !important;\n}\n\n.AttributesContent_details-container-row-header__GVlGW:first-child {\n  border-top: unset !important;\n}\n\n.AttributesContent_details-container__1apzO {\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 12px;\n  gap: 12px;\n}\n\n.AttributesContent_details-container-card__2Kaip > div {\n  border-radius: 4px;\n}\n\n.AttributesContent_details-container-card__2Kaip {\n  padding: 2px 8px;\n}\n\n.AttributesContent_fees__fRaCD {\n  grid-gap: 12px;\n  gap: 12px;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.AttributesContent_details-header__3Fodh{\n  border-top: 1px solid var(--Gris-20, #CCD1E4)!important;\n  border-bottom: 1px solid var(--Gris-20, #CCD1E4);\n  background: #ECFEFC;\n  color: var(--Gris-100, #68688C);\n  font-family: Inter;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 21px; /* 175% */\n  letter-spacing: 0.24px;\n}", ""]);
// Exports
exports.locals = {
	"details-container-row-header": "AttributesContent_details-container-row-header__GVlGW",
	"details-container": "AttributesContent_details-container__1apzO",
	"details-container-card": "AttributesContent_details-container-card__2Kaip",
	"fees": "AttributesContent_fees__fRaCD",
	"details-header": "AttributesContent_details-header__3Fodh"
};
module.exports = exports;
