import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { productEntities } from '../../../../../../components/PromoWizard/Steps/ConditionsForm/variables'
import { Table, Head, Body, Cell, Row } from '../../../../Components/ViewTable'
import numberFormatter from 'utils/numberFormatter'
import { ViewTag } from '../../../../Components'
import style from './ProductsTable.module.css'
import { InnerTable } from '..'
import i18n from 'i18n'
import { getEntities } from 'api/EntitiesAPI'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const ProductsTable = ({ productsInfo, className = '' }) => {
  const [fetchedEntities, setFetchedEntities] = useState({})
  const [entityOpen, setEntityOpen] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [values, setValues] = useState([])

  const { numberFormat } = numberFormatter()
  const { t } = useTranslation('viewPromo')

  const changeEntityOpen = id => {
    setEntityOpen(prev => (prev === id ? undefined : id))
  }

  useEffect(() => {
    setValues(
      productsInfo.map(item => ({
        ...item,
        entity: i18n.t(`${productEntities.find(({ value }) => item.entity === value).name}`),
        endpoint: productEntities.find(({ value }) => item.entity === value).endpoint,
      }))
    )
  }, [productsInfo])

  useEffect(() => {
    const fetchData = async (endpoint, entity_details) => {
      setLoading(true)
      const response = await getEntities(
        `${endpoint}/ids?ids=${entity_details.map(element => element.entity_id).join(',')}`
      )
      setLoading(false)

      setFetchedEntities(prev => ({
        ...prev,
        [entityOpen]: response.data.map(({ id, description }) => ({
          id,
          label: description,
        })),
      }))

      setOptions(
        response.data.map(({ id, description }) => ({
          id,
          label: description,
        }))
      )
    }

    if (entityOpen !== undefined) {
      const { endpoint, entity_details } = values.find(item => item.id === entityOpen)

      if (fetchedEntities[entityOpen]) {
        setOptions(fetchedEntities[entityOpen])
      } else {
        fetchData(endpoint, entity_details)
      }
    }
  }, [entityOpen, fetchedEntities, values])

  return (
    productsInfo?.length !== 0 && (
      <Table entity={'Producto'} className={className}
        search={true}
        renderSearchValue={(searchValue) => {
          const valueFiltered = values.filter( item  =>  item.entity.toLowerCase().includes(searchValue.toLowerCase()) || t(`conditions.products.conditionsValue.${item.details.calculation_type}`).toLowerCase().includes(searchValue.toLowerCase()) || t(`conditions.products.standard.${item.details.calculation_type}`).toLowerCase().includes(searchValue.toLowerCase()) || (item.details.calculation_type === 'RANGE_AMOUNT' ||
          item.details.calculation_type === 'RANGE_QUANTITY'
          ? `$${numberFormat.format(parseFloat(item.details.range_from).toFixed(2))} a $${numberFormat.format(
            parseFloat(item.details.range_to).toFixed(2)
          )}`
          : `${item.details.calculation_type.toLowerCase().includes('amount') ? '$' : ''}` +
          numberFormat.format(parseFloat(item.details.range_to).toFixed(2))).includes(searchValue.toLowerCase()))
          return (
            <>
              <Head
                view={'conditions.products'}
                columns={[
                  { value: 'attributes' },
                  { value: 'options' },
                  { value: 'standard' },
                  { value: 'conditions-value' },
                  { value: 'value', className: style['aling-right'] },
                ]}
              />
              <Body>
                {valueFiltered.length>0 ? valueFiltered?.map(item => (
                  <React.Fragment key={item.id}>
                    <Row
                      withArrow={item.entity_details.length !== 0}
                      onClickArrow={() => changeEntityOpen(item.id)}
                      className={`${entityOpen === item.id ? style.isOpen : ''}`}>
                      <Cell bold>{item.entity}</Cell>
                      <Cell>
                        {item.entity_details.length !== 0 && (
                          <ViewTag
                            color={'#E4FDFA'}
                            fontColor={'#348888'}
                            borderColor={'#348888'}
                            label={`${item.entity_details.length} seleccionados`}
                          />
                        )}
                      </Cell>
                      <Cell>{t(`conditions.products.standard.${item.details.calculation_type}`)}</Cell>
                      <Cell>{t(`conditions.products.conditionsValue.${item.details.calculation_type}`)}</Cell>
                      <Cell className={style['aling-right']}>
                        {item.details.calculation_type === 'RANGE_AMOUNT' ||
                          item.details.calculation_type === 'RANGE_QUANTITY'
                          ? `$${numberFormat.format(parseFloat(item.details.range_from).toFixed(2))} a $${numberFormat.format(
                            parseFloat(item.details.range_to).toFixed(2)
                          )}`
                          : `${item.details.calculation_type.toLowerCase().includes('amount') ? '$' : ''}` +
                          numberFormat.format(parseFloat(item.details.range_to).toFixed(2))}
                      </Cell>
                    </Row>
                    <InnerTable
                      colSpan={6}
                      itemOpen={entityOpen === item.id}
                      name={'conditions.products'}
                      columns={['article', 'products']}
                      className={style.innerTableProducts}
                      loading={entityOpen === item.id && loading}>
                      {options.map(({ id, label }) => (
                        <Row key={id}>
                          <Cell>
                            <span>{id}</span>
                          </Cell>
                          <Cell >
                            <span>{label}</span>
                          </Cell>
                        </Row>
                      ))}
                    </InnerTable>
                  </React.Fragment>
                )) :
                  (
                    <Row>
                      <Cell colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                        <EmptyState />
                      </Cell>
                    </Row>
                  )}
              </Body>
            </>
          )
        }}
      />
    )
  )
}

export default ProductsTable
