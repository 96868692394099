import React from 'react'

import style from './Cell.module.css'

const Cell = ({ children, bold = false, className = '', colSpan, styles = {} }) => {
  return (
    <td className={`${style.td} ${className}`} colSpan={colSpan}>
      {typeof children === 'string' ? (
        <div className={style.container} style={{...styles}}>
          <span className={`${style.span} ${bold ? style.bold : ''}`}>{children}</span>
        </div>
      ) : (
        children
      )}
    </td>
  )
}

export default Cell
