import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import BaseSelect from 'react-select'
import './ConditionsForm.css'
import { useTranslation } from 'react-i18next'
import { getEntities } from '../../../../api/EntitiesAPI'
import FixRequiredSelect from '../../../common/RequiredSelect'
import { paymentMethodTypes, ruleTypes, calculationTypes, paymentMethodTypesArgExclusive } from './variables'
import { CreatePromoContext } from '../../../../context/create-promo-context'
import axios from 'axios'
import NumberInput from '../../../common/NumberInput'
import { validIdsURLElement } from 'utils/regularExpresion'
import { AttributeSelector, CommonInput, Loading } from '../ActionsForm/components'
import PaymentMethodSelector from './Components/PaymentMethodSelector'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'
import ToggleWithTooltip from '../GeneralSettingsForm/Components/ToggleWithTooltip/ToggleWithTooltip'

export const Select = props => <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />

const ConditionsForm = props => {
  const loadRuleType = () => {
    if (props.form) {
      let rule = ruleTypes.find(rule => {
        return rule.entities.find(entity => entity.value === props.form.entity)
      })
      if (!rule) {
        let paymentMethodType = paymentMethodTypesArgExclusive.find(
          paymentMethodType => paymentMethodType.value === props.form.entity
        )
        if (paymentMethodType) {
          return ruleTypes[2]
        }
      }
      return rule
    }
    return null
  }

  const loadPaymentMethodType = () => {
    if (props.form) {
      const currentMethodType = paymentMethodTypesArgExclusive.find(p => p.value === props.form.entity)
      return currentMethodType
    }
    return null
  }

  const loadEntityType = () => {
    if (props.form) {
      let rule = ruleTypes.find(rule => {
        return rule.entities.find(entity => entity.value === props.form.entity)
      })
      if (!rule) {
        let paymentMethodType = paymentMethodTypesArgExclusive.find(
          paymentMethodType => paymentMethodType.value === props.form.entity
        )
        if (paymentMethodType) {
          rule = ruleTypes[2]
          let entitySelected = rule.entities.find(entity => entity.value === 'PAYMENT_METHODS')
          let entityIndex = rule.entities.findIndex(entity => entity.value === 'PAYMENT_METHODS')
          entitySelected.index = entityIndex
          return entitySelected
        }
      }

      let entitySelected = rule.entities.find(entity => entity.value === props.form.entity)
      let entityIndex = rule.entities.findIndex(entity => entity.value === props.form.entity)
      entitySelected.index = entityIndex
      return entitySelected
    }
    return null
  }

  const loadEntities = () => {
    if (props.form?.entity_details?.length && entityType) {
      const endpoint = entityType.value === 'PAYMENT_METHODS' ? paymentMethodType.endpoint : entityType.endpoint
      setLoading(true)
      getEntities(`${endpoint}/ids?ids=${props.form.entity_details.map(element => element?.entity_id).join(',')}`)
        .then(({ data }) => {
          setLoading(false)
          setEntities(
            data.map(({ id, description }) =>
              endpoint != '/product'
                ? { value: id, label: description }
                : { value: id, label: id + ' - ' + description }
            )
          )
        })
        .catch(err => console.log(err))
    }
  }

  const loadPaymentMethod = paymentMethodOptions => {
    if (props.form) {
      let paymentMethodFiltered = null
      if (paymentMethodOptions && paymentMethodOptions.length > 0) {
        paymentMethodFiltered = paymentMethodOptions.filter(paymentMethodOption =>
          props.form.entity_details.map(element => element?.entity_id).includes(paymentMethodOption.value)
        )
      }
      if (paymentMethodType.value === 'CREDIT_CARD') {
        if (props.form && props.form.entity_details) {
          const cardSelected = paymentMethodFiltered
            .map(card => {
              let entitiesID = props.form.entity_details.find(element => element.entity_id === card.value)
              return { ...card, fees: card.fees.filter(fee => entitiesID.detail?.fees?.includes(fee)) }
            })
            .filter(card => card.fees.length > 0)

          const creditCardValues = cardSelected.reduce((acc, card) => {
            acc[card.value] = {
              value: card.value,
              label: card.label,
              fees: card.fees,
            }
            return acc
          }, {})
          return setCreditCardValues(creditCardValues)
        }
      }
      setPaymentMethod(paymentMethodFiltered)
    }
  }

  const loadCalculateBy = () => {
    if (props.form) {
      if (props.form.details?.calculation_type) {
        let calculationTypeFiltered = calculationTypes['quantity'].find(
          calculationTypeOption => calculationTypeOption.value === props.form.details.calculation_type
        )
        if (calculationTypeFiltered) {
          setCalculateBy('quantity')
          setCalculationTypeOptions(calculationTypes['quantity'])
          setCalculationType(calculationTypeFiltered.value)
          return
        }

        let calculationTypeFilteredPrice = calculationTypes['price'].find(
          calculationTypeOption => calculationTypeOption.value === props.form.details.calculation_type
        )
        if (calculationTypeFilteredPrice) {
          setCalculateBy('price')
          setCalculationTypeOptions(calculationTypes['price'])
          setCalculationType(props.form.details.calculation_type)
          return
        }
      }
    }
  }

  const [ruleType, setRuleType] = useState(loadRuleType)
  const [entityType, setEntityType] = useState(loadEntityType)
  const [paymentMethodType, setPaymentMethodType] = useState(loadPaymentMethodType)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [fees, setFees] = useState([])
  const [entityOptions, setEntityOptions] = useState([])
  const [filteredEntityOptions, setFilteredEntityOptions] = useState([])
  const [filterEntityBy, setFilterEntityBy] = useState('')
  const [page, setPage] = useState(0)
  const [fetchedAllPages, setFetchedAllPages] = useState(false)
  const [entities, setEntities] = useState([])
  const [calculateBy, setCalculateBy] = useState('quantity')
  const [calculationType, setCalculationType] = useState(calculationTypes[calculateBy][0].value)
  const [calculationTypeOptions, setCalculationTypeOptions] = useState(calculationTypes[calculateBy])
  const [rangeFrom, setRangeFrom] = useState(
    props.form && props.form.details?.range_from ? props.form.details.range_from : 0
  )
  const [rangeTo, setRangeTo] = useState(props.form && props.form.details?.range_to ? props.form.details.range_to : 0)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation('createPromo')
  const context = useContext(CreatePromoContext)
  const [errors, setErrors] = useState([])
  const [cancelToken, setCancelToken] = useState(null)
  const [filterCancelToken, setFilterCancelToken] = useState(null)
  const IS_ARG = i18n && i18n.language && i18n.language.includes('es_arg')
  const cancelTokenRef = useRef()
  const [exclusivePaymentMethod, setExclusivePaymentMethod] = useState(props.form && props.form.exclusive)
  const [excludePaymentMethod, setExcludePaymentMethod] = useState(props.form && props.form.excluded)

  const [attributeSelectorOpen, setAttributeSelectorOpen] = useState(false)
  const [entityValid, setEntityValid] = useState('')
  const [attributeCurrent, setAttributeCurrent] = useState({})
  const [calculationTypeValue, setCalculationTypeValue] = useState({})
  const [ruleTypeValue, setRuleTypeValue] = useState({})
  const [isOpenCreditCardSelector, setIsOpenCreditCardSelector] = useState(false)
  const [creditCardValues, setCreditCardValues] = useState({})
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState({ onConfirm: () => {}, onCancel: () => {} })
  const [firstTimeRender, setFirstTimeRender] = useState(true)

  const handleMenuOpen = event => {
    context.setMenuIsOpen(true)
  }

  const handleMenuClose = () => {
    context.setMenuIsOpen(false)
  }

  useEffect(() => {
    let isValid = ''
    if (entities.length > 0) isValid = 'true'
    setEntityValid(isValid)
  }, [entities, setEntityValid])

  useEffect(() => {
    if (entityType?.value)
      setAttributeCurrent({ entity: entityType?.value, ids: entities.map(element => element.value) })
  }, [entities, entityType])

  useEffect(() => {
    // https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
    cancelTokenRef.current = cancelToken
  }, [cancelToken])

  useEffect(() => {
    if (entityType && entityType.hasOwnProperty('endpoint')) {
      if (cancelToken) {
        cancelToken.cancel()
      }
      setEntityOptions([])
      setFetchedAllPages(false)
      setPage(0)
    }
  }, [entityType])

  useEffect(() => {
    if (paymentMethodType && paymentMethodType.hasOwnProperty('endpoint')) {
      setLoading(true)
      getEntities(paymentMethodType.endpoint)
        .then(({ data: { content } }) => {
          setLoading(false)
          setPaymentMethodOptions(
            content.map(({ id, description, fees }) => ({
              value: id,
              label: description,
              fees: fees,
            }))
          )
          if (firstTimeRender) {
            setFirstTimeRender(false)
            if (props.form?.entity === paymentMethodType?.value) {
              loadPaymentMethod(
                content.map(({ id, description, fees }) => ({
                  value: id,
                  label: description.replaceAll('_', ' '),
                  fees: fees,
                }))
              )
            }
          }
        })
        .catch(err => console.log(err))
    }
  }, [paymentMethodType])

  useEffect(() => {
    loadCalculateBy(calculateBy, calculationTypeOptions)
    loadEntities()
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel()
      }
    }
  }, [])

  useEffect(() => {
    setErrors([])
    setFilterEntityBy('')
    setFilteredEntityOptions([])
  }, [ruleType, entityType, paymentMethodType])

  useEffect(() => {
    setErrors([])
  }, [rangeFrom, rangeTo, entities])

  useEffect(() => {
    if (filterEntityBy) {
      if (filterCancelToken) {
        filterCancelToken.cancel()
      }
      const source = axios.CancelToken.source()
      setFilterCancelToken(source)

      setLoading(true)
      getEntities(`${entityType.endpoint}?filter=${filterEntityBy}`, {
        cancelToken: source.token,
      })
        .then(({ data: { content } }) => {
          setLoading(false)
          const filteredOptions = content.map(({ id, description }) =>
            entityType.endpoint != '/product'
              ? { value: id, label: description }
              : { value: id, label: id + ' - ' + description }
          )
          setFilteredEntityOptions(filteredOptions)
        })
        .catch(err => console.log(err))
    }
  }, [filterEntityBy])

  const fetchEntities = page => {
    const source = axios.CancelToken.source()
    setCancelToken(source)

    setLoading(true)
    getEntities(`${entityType.endpoint}?size=200&page=${page}`, {
      cancelToken: source.token,
    })
      .then(({ data }) => {
        setLoading(false)
        // setCancelToken(null)

        // Append results to previous entity Options
        const newOptions = data.content.map(({ id, description }) =>
          entityType.endpoint != '/product'
            ? { value: id, label: description }
            : { value: id, label: id + ' - ' + description }
        )
        setEntityOptions(prevEntityOptions => {
          return data.first ? newOptions : [...prevEntityOptions, ...newOptions]
        })

        if (!data.last) {
          setPage(page + 1)
        } else {
          setFetchedAllPages(true)
        }
      })
      .catch(err => console.log(err))
  }

  const onEntitySearch = (keyword, action) => {
    if (action === 'input-change') {
      setFilterEntityBy(keyword)
    }
  }

  const onEntitySelectClose = () => {
    setLoading(false)
    if (cancelToken) {
      cancelToken.cancel()
    }
  }

  const fetchNextPage = () => {
    if (!fetchedAllPages && !loading && !filterEntityBy) {
      fetchEntities(page)
    }
  }

  const onEntitySelectOpen = () => {
    fetchNextPage()
  }

  const onEntityScrollBottom = () => {
    fetchNextPage()
  }

  const transformCreditCardObject = creditCardObject => {
    const transformed = {}
    Object.keys(creditCardObject).forEach(key => {
      transformed[key] = creditCardObject[key].fees
    })
    return transformed
  }

  function convertPaymentToDTO(entityDetails) {
    let result = []
    Object.keys(entityDetails).forEach(key => {
      let entity = {
        entity_id: key,
        detail: {
          fees: entityDetails[key].fees,
        },
      }
      result.push(entity)
    })
    return result
  }

  const onSubmit = e => {
    e.preventDefault()

    const availableRules = context.form.body.rules?.filter((r, idx) => {
      return (
        (!r.hasOwnProperty('id') &&
          !(props.isEditing && Number.isFinite(props.editingIndex) && idx === props.editingIndex)) ||
        r.id !== props.form?.id
      )
    })

    if (rangeFrom > 9999999999999.99) {
      setErrors([`${t('steps.conditions.form.error_max_import')}`])
      return
    }
    if (rangeTo > 9999999999999.99) {
      setErrors([`${t('steps.conditions.form.error_max_import')}`])
      return
    }

    let arrayInvalid = validIdsURLElement(entities)
    if (arrayInvalid.length > 0) {
      setErrors([`${t('steps.actions.form.error_ids')} ${arrayInvalid.map(id => '"' + id.label + '"').join(', ')}`])
      return
    }

    if (
      availableRules.find(
        r =>
          r.entity === entityType.value ||
          (paymentMethodTypesArgExclusive.find(p => p.value === r.entity) && entityType.value === 'PAYMENT_METHODS')
      )
    ) {
      setErrors([`${t('steps.conditions.form.existing_rule')} ${t(entityType.name)} (${ruleType.name})`])
      return
    }

    if (calculationType === 'RANGE_AMOUNT' && rangeTo == 0) {
      setErrors([`${t('steps.conditions.form.range_to_cero')} `])
      return
    }

    const usedEntities =
      entityType.value === 'PAYMENT_METHODS'
        ? paymentMethodType.value === 'CREDIT_CARD'
          ? convertPaymentToDTO(creditCardValues)
          : paymentMethod.map(ent => ({ entity_id: ent.value }))
        : entities.map(ent => ({ entity_id: ent.value }))

    let rule = {
      entity: entityType.value === 'PAYMENT_METHODS' ? paymentMethodType.value : entityType.value,
      entity_details: usedEntities,
      exclusive: entityType.value === 'PAYMENT_METHODS' ? exclusivePaymentMethod : false,
      excluded: entityType.value === 'PAYMENT_METHODS' ? excludePaymentMethod : false,
    }

    if (props.form?.hasOwnProperty('id') && props.isEditing) {
      rule['id'] = props.form.id
    }

    if (calculationType === 'EQUAL_QUANTITY' && ruleType.name === t('steps.conditions.form.product')) {
      if (parseFloat(rangeFrom) === 0) {
        setErrors([`${t('steps.conditions.form.equal_zero')}`])
        return
      }
    }

    if (ruleType.name === t('steps.conditions.form.product') || entityType.value === 'AMOUNT') {
      if (calculationType.includes('RANGE')) {
        if (parseFloat(rangeTo) < parseFloat(rangeFrom)) {
          setErrors([`${t('steps.conditions.form.range_rule')}`])
          return
        }
      }
      rule['details'] = {
        calculation_type: calculationType,
        range_from: rangeFrom,
        range_to: calculationType.includes('RANGE') ? rangeTo : rangeFrom,
      }
    }

    if (entityType.value === 'PAYMENT_METHODS') {
      delete rule.details
    }
    if (entityType.value === 'AMOUNT') {
      delete rule.entity_details
    }
    props.addSetting(rule)
  }

  useEffect(() => {
    setCalculationTypeValue({
      label: calculationTypeOptions.find(item => item.value === calculationType)?.name,
      value: calculationTypeOptions.find(item => item.value === calculationType)?.value,
    })
  }, [calculationType])

  useEffect(() => {
    setRuleTypeValue(
      ruleType
        ? {
            label: ruleTypes.find(ruleT => ruleT.name === ruleType.name).name,
            value: ruleTypes.findIndex(ruleT => ruleT.name === ruleType.name).name,
          }
        : ruleType
    )
  }, [ruleType])

  useEffect(() => {
    const modals = document.querySelectorAll('.modal-dialog')

    modals.forEach(modal => {
      const hasConditionsForm = modal.querySelector('.conditions-form')

      if (hasConditionsForm) {
        if (isOpenCreditCardSelector) {
          modal.classList.add('has-conditions-form')
        } else {
          modal.classList.remove('has-conditions-form')
        }
      }
    })
  }, [isOpenCreditCardSelector])

  const changePaymentMethod = param => {
    const auxPaymentMT = IS_ARG ? paymentMethodTypesArgExclusive[param.value] : paymentMethodTypes[param.value]

    const openConfirmationAlert = confirmCallback => {
      setIsOpenAlert(true)
      const handleConfirm = () => {
        confirmCallback()
        setIsOpenAlert(false)
      }
      const handleCancel = () => setIsOpenAlert(false)
      setConfirmationCallback({ handleConfirm, handleCancel })
    }

    const setNewPaymentMethod = () => {
      setPaymentMethod('')
      setCreditCardValues({})
      setPaymentMethodOptions([])
      setPaymentMethodType(auxPaymentMT)
    }

    const confirmAndChangePaymentMethod = () => {
      if (paymentMethodType.value !== auxPaymentMT.value) {
        if (paymentMethodType.value !== 'CREDIT_CARD') {
          if (paymentMethod && paymentMethod.length > 0) {
            openConfirmationAlert(() => setNewPaymentMethod())
          } else {
            setNewPaymentMethod()
          }
        } else {
          if (Object.keys(creditCardValues).length > 0) {
            openConfirmationAlert(() => setNewPaymentMethod())
          } else {
            setNewPaymentMethod()
          }
        }
      }
    }

    confirmAndChangePaymentMethod()
  }

  const validatePaymenthMethod = () => {
    return Object.keys(creditCardValues).length !== 0 ? 'true' : ''
  }

  useEffect(() => {
    if (props.form) {
      setExcludePaymentMethod(props.form.excluded)
      setExclusivePaymentMethod(props.form.exclusive)
    }
  }, [props.form])

  return (
    <form onSubmit={onSubmit} className="conditions-form" id="add-promo-form">
      <PopUpAlert
        value="¿Deseas cambiar el medio de pago?"
        extraText="Al hacerlo, se perderán los datos configurados del medio de pago actual."
        isOpen={isOpenAlert}
        setIsOpen={setIsOpenAlert}
        onConfirm={{
          value: 'Cambiar',
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
        onCancel={{
          value: 'Cancelar',
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
      />
      <h1>{t('steps.conditions.title')}</h1>
      <FormGroup tag="fieldset">
        <FormGroup>
          <Label>{t('steps.conditions.entity')}</Label>
          <Select
            isSearchable={false}
            className="form-control p-0"
            classNamePrefix="react-select"
            name="selection"
            value={ruleTypeValue}
            onChange={e => {
              if (ruleType?.name !== ruleTypes[e.value].name) {
                setRangeFrom(0)
                setFees([])
                setEntityType(null)
                setRuleType(ruleTypes[e.value])
              }
            }}
            options={ruleTypes.map((rule, idx) => {
              return { label: rule.name, value: idx }
            })}
            placeholder={t('steps.conditions.select')}
            required
            styles={{
              container: baseStyles => ({
                ...baseStyles,
              }),
              control: baseStyles => ({
                ...baseStyles,
                cursor: 'pointer',
              }),
              option: baseStyles => ({
                ...baseStyles,
                cursor: 'pointer',
                fontWeight: 'bolder',
              }),
            }}
          />
        </FormGroup>

        {ruleType && (
          <FormGroup id={'condition-form-form-group'}>
            <Label>{t('steps.conditions.attribute')}</Label>
            <Select
              isSearchable={false}
              className="form-control p-0"
              classNamePrefix="react-select"
              name="type"
              value={entityType ? { label: `${t(entityType.name)}`, value: entityType.index } : ''}
              onChange={e => {
                const auxEntityType = ruleType.entities[e.value]
                if (entityType?.value !== auxEntityType?.value) {
                  setEntities([])
                  setPaymentMethod('')
                  setPaymentMethodType('')
                  setEntityType(auxEntityType)
                  const calculateBy = auxEntityType?.value === 'AMOUNT' ? 'price' : 'quantity'
                  setCalculateBy(calculateBy)
                  setCalculationTypeOptions(calculationTypes[calculateBy])
                  setCalculationType(
                    auxEntityType?.value === 'AMOUNT' ? 'GREATER_EQUAL_AMOUNT' : calculationTypes[calculateBy][0].value
                  )
                }
              }}
              placeholder={t('steps.conditions.select')}
              options={ruleType.entities.map((entity, idx) => {
                return { label: `${t(entity.name)}`, value: idx }
              })}
              required
              styles={{
                container: baseStyles => ({
                  ...baseStyles,
                }),
                control: baseStyles => ({
                  ...baseStyles,
                  cursor: 'pointer',
                }),
                option: baseStyles => ({
                  ...baseStyles,
                  cursor: 'pointer',
                  fontWeight: 'bolder',
                }),
              }}
            />
          </FormGroup>
        )}

        {entityType && (
          <>
            <FormGroup>
              {ruleType.name !== t('steps.conditions.form.voucher') && entityType.value === 'PAYMENT_METHODS' ? (
                <Label>{t('steps.conditions.options')}</Label>
              ) : null}
              {ruleType.name !== t('steps.conditions.form.voucher') ? (
                ruleType.name === 'PAYMENT_METHODS' ? (
                  <Select
                    isSearchable={false}
                    className="form-control p-0"
                    classNamePrefix="react-select"
                    placeholder={`${t('steps.conditions.form.select')}` + ' ' + t(entityType.name)}
                    loadingMessage={() => `${t('steps.conditions.form.loading')}`}
                    noOptionsMessage={() => t('steps.conditions.form.no_results')}
                    name="multipleSelect"
                    closeMenuOnSelect={false}
                    isMulti
                    value={entities || []}
                    onChange={v => {
                      setEntities(v)
                    }}
                    onMenuClose={() => {
                      handleMenuClose()
                      setFilterEntityBy('')
                      onEntitySelectClose()
                    }}
                    onMenuOpen={() => {
                      handleMenuOpen()
                      onEntitySelectOpen()
                    }}
                    onMenuScrollToBottom={onEntityScrollBottom}
                    options={filterEntityBy ? filteredEntityOptions : entityOptions}
                    required
                    onInputChange={(keyword, { action }) => onEntitySearch(keyword, action)}
                    isLoading={loading}
                    styles={{
                      container: baseStyles => ({
                        ...baseStyles,
                      }),
                      control: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                      }),
                      option: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        fontWeight: 'bolder',
                      }),
                    }}
                  />
                ) : (
                  <CommonInput
                    label={t('steps.conditions.options')}
                    labelPlural={t('steps.conditions.selected_options')}
                    labelSingular={t('steps.conditions.selected_option')}
                    onClick={() => {
                      setAttributeSelectorOpen(!attributeSelectorOpen)
                    }}
                    onClickCross={() => setEntities([])}
                    objectsSize={entities.length}
                    loading={loading}
                    requiredValid={true}
                    entityValid={entityValid}
                  />
                )
              ) : entityType.value === 'PAYMENT_METHODS' ? (
                <Select
                  isSearchable={false}
                  className="form-control p-0"
                  classNamePrefix="react-select"
                  name="type"
                  value={
                    paymentMethodType
                      ? {
                          label: `${t(paymentMethodType.name)}`,
                          value: paymentMethodTypesArgExclusive.findIndex(
                            ruleT => ruleT.name === paymentMethodType.name
                          ),
                        }
                      : t('steps.conditions.select')
                  }
                  onChange={e => changePaymentMethod(e)}
                  options={
                    IS_ARG
                      ? paymentMethodTypesArgExclusive.map((e, index) => {
                          return { label: `${t(e.name)}`, value: index }
                        })
                      : paymentMethodTypes.map((e, index) => {
                          return { label: `${t(e.name)}`, value: index }
                        })
                  }
                  placeholder={t('steps.conditions.select')}
                  required
                  styles={{
                    container: baseStyles => ({
                      ...baseStyles,
                    }),
                    control: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                    option: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                    }),
                  }}
                />
              ) : null}
            </FormGroup>
            {paymentMethodType && paymentMethodType.value !== 'CREDIT_CARD' && (
              <FormGroup>
                <Label>{t(paymentMethodType.name)}</Label>
                <Select
                  id={'excluded_list_select'}
                  className="form-control p-0"
                  classNamePrefix="react-select"
                  name="multipleSelect"
                  placeholder={t('steps.conditions.form.select')}
                  loadingMessage={() => `${t('steps.conditions.form.loading')}`}
                  noOptionsMessage={() => t('steps.conditions.form.no_results')}
                  value={paymentMethod || ''}
                  onChange={v => {
                    setFees([])
                    setPaymentMethod(v)
                  }}
                  isMulti={paymentMethodType.value !== 'CREDIT_CARD'}
                  options={paymentMethodOptions}
                  closeMenuOnSelect={paymentMethodType.value === 'CREDIT_CARD'}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  optionCheck={paymentMethodType.value !== 'CREDIT_CARD'}
                  labelList={'seleccionados'}
                  hideSelectedOptions={false}
                  required
                  isLoading={loading}
                  styles={{
                    container: baseStyles => ({
                      ...baseStyles,
                    }),
                    control: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                    option: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                    }),
                  }}
                />
                {paymentMethodType?.value !== 'CREDIT_CARD' && paymentMethod?.length > 2 ? (
                  <button
                    id="btnSelectCardClear"
                    type="button"
                    onClick={() => setPaymentMethod(null)}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '26%',
                      width: '3ch',
                      height: '3ch',
                      opacity: '0',
                    }}
                  />
                ) : null}
              </FormGroup>
            )}
            {paymentMethodType && paymentMethodType.value === 'CREDIT_CARD' ? (
              <CommonInput
                label={'Tarjeta'}
                labelPlural={'Tarjetas seleccionadas'}
                labelSingular={'Tarjeta seleccionada'}
                onClick={() => setIsOpenCreditCardSelector(true)}
                onClickCross={() => setCreditCardValues({})}
                objectsSize={Object.keys(creditCardValues).length}
                loading={loading}
                placeholder={'Agregar Tarjeta'}
                requiredValid={true}
                entityValid={validatePaymenthMethod()}
              />
            ) : null}
            {entityType?.value === 'PAYMENT_METHODS' && (
              <FormGroup>
                <ToggleWithTooltip
                  label={'Medio de pago exclusivo de esta promoción'}
                  value={exclusivePaymentMethod}
                  onChange={() => {
                    if (excludePaymentMethod) {
                      setExcludePaymentMethod(!excludePaymentMethod)
                    }
                    setExclusivePaymentMethod(!exclusivePaymentMethod)
                  }}>
                  <h3>Medio de pago exclusivo</h3>
                  <p>
                    Al activar esta opción, la promoción solo es válida si se utiliza exclusivamente el medio de pago
                    especificado.
                  </p>
                </ToggleWithTooltip>
                <ToggleWithTooltip
                  label={'Excluir medio de pago en esta promoción'}
                  value={excludePaymentMethod}
                  onChange={() => {
                    if (exclusivePaymentMethod) {
                      setExclusivePaymentMethod(!exclusivePaymentMethod)
                    }
                    setExcludePaymentMethod(!excludePaymentMethod)
                  }}>
                  <h3>Excluir medio de pago</h3>
                  <p>
                    Al activar esta opción, si está presente en el comprobante el medio de pago seleccionado, la
                    promoción no se aplicará.
                  </p>
                </ToggleWithTooltip>
              </FormGroup>
            )}
            {/* Calculate By section*/}
            {ruleType.name !== t('steps.conditions.form.customer') && entityType.value !== 'PAYMENT_METHODS' && (
              <>
                {entityType.value !== 'AMOUNT' && (
                  <>
                    <h2 className="mt-4">{t('steps.conditions.form.calculate_by')}</h2>
                    <FormGroup>
                      <Label>{t('steps.conditions.quantity_criteria')}</Label>
                      <Select
                        className="form-control p-0"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        name="type"
                        value={{ value: calculateBy, label: t(`steps.conditions.form.${calculateBy}`) }}
                        onChange={e => {
                          setCalculationType(
                            ruleType.name === t('steps.conditions.form.product') && e.value === 'price'
                              ? 'GREATER_EQUAL_AMOUNT'
                              : calculationTypes[e.value][0].value
                          )
                          setRangeFrom(0)
                          setRangeTo(0)
                          setCalculateBy(e.value)
                          setCalculationTypeOptions(calculationTypes[e.value])
                        }}
                        options={[
                          {
                            value: 'quantity',
                            label: t('steps.conditions.form.quantity'),
                          },
                          {
                            value: 'price',
                            label: t('steps.conditions.form.price'),
                          },
                        ]}
                        styles={{
                          container: baseStyles => ({
                            ...baseStyles,
                          }),
                          control: baseStyles => ({
                            ...baseStyles,
                            cursor: 'pointer',
                          }),
                          option: baseStyles => ({
                            ...baseStyles,
                            cursor: 'pointer',
                            fontWeight: 'bolder',
                          }),
                        }}
                      />
                    </FormGroup>
                  </>
                )}

                <FormGroup>
                  <Label>{t('steps.conditions.range')}</Label>
                  <Select
                    className="form-control p-0"
                    classNamePrefix="react-select"
                    isSearchable={false}
                    name="type"
                    value={calculationTypeValue}
                    onChange={e => {
                      setRangeFrom(0)
                      setRangeTo(0)
                      setCalculationType(e.value)
                    }}
                    options={calculationTypeOptions
                      .filter(c =>
                        entityType.value === 'AMOUNT' || ruleType.name === t('steps.conditions.form.product')
                          ? c.value !== 'EQUAL_AMOUNT'
                          : c
                      )
                      .map(c => ({ value: c.value, label: c.name }))}
                    required
                    styles={{
                      container: baseStyles => ({
                        ...baseStyles,
                      }),
                      control: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                      }),
                      option: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        fontWeight: 'bolder',
                      }),
                    }}
                  />
                </FormGroup>
                {calculationType === 'RANGE_AMOUNT' ? (
                  <FormGroup>
                    <Label>{t('steps.conditions.range_between')}</Label>
                    <div className="flex-row d-flex">
                      <NumberInput
                        min={0}
                        increment={1}
                        precision={2}
                        required
                        value={rangeFrom}
                        type="number"
                        onChange={setRangeFrom}
                      />
                      <p className="mt-auto mb-auto mr-3 ml-3">{t('steps.conditions.form.to')}</p>
                      <NumberInput
                        min={rangeFrom ? rangeFrom : ''}
                        increment={1}
                        type="number"
                        required
                        precision={2}
                        value={rangeTo}
                        onChange={setRangeTo}
                      />
                    </div>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label>{t('steps.conditions.range_value')}</Label>
                    <NumberInput
                      min={0.01}
                      required
                      type="number"
                      increment={1}
                      precision={2}
                      value={rangeFrom}
                      onChange={setRangeFrom}
                    />
                  </FormGroup>
                )}
              </>
            )}
          </>
        )}
      </FormGroup>
      {isOpenCreditCardSelector ? (
        <PaymentMethodSelector
          setFees={setFees}
          isLoading={loading}
          feesValue={fees || []}
          value={paymentMethod || ''}
          isOpen={isOpenCreditCardSelector}
          creditCardValues={creditCardValues}
          setPaymentMethod={setPaymentMethod}
          setIsOpen={setIsOpenCreditCardSelector}
          setCreditCardValues={setCreditCardValues}
          paymentMethodOptions={paymentMethodOptions}
          availablePaymentMethods={paymentMethodTypes}
        />
      ) : null}
      {attributeSelectorOpen ? (
        <AttributeSelector
          byEntity={entityType}
          loading={loading}
          setLoading={setLoading}
          entities={entities}
          setEntities={setEntities}
          openSelect={attributeSelectorOpen}
          setOpenSelect={setAttributeSelectorOpen}
          entityOptions={entityOptions}
          setEntityOptions={setEntityOptions}
          onEntitySelectOpen={onEntitySelectOpen}
          filteredEntityOptions={filteredEntityOptions}
          filterEntityBy={filterEntityBy}
          setFilterEntityBy={setFilterEntityBy}
          fetchedAllEntityPages={fetchedAllPages}
          setFetchedAllEntityPages={setFetchedAllPages}
          AttributeCurrent={attributeCurrent}
        />
      ) : null}
      {errors.map((e, index) => (
        <p style={{ color: 'red' }} key={index}>
          {e}
        </p>
      ))}
    </form>
  )
}

export default ConditionsForm
