// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewWhere_options__3UZcZ > div {\n  margin-left: auto;\n}\n.ViewWhere_amountColHead__1nWdL td:last-of-type{\n    width: 0;\n    padding: 0;\n}\n\n.ViewWhere_amountColHead__1nWdL th, .ViewWhere_amountColHead__1nWdL+tbody td{\n  padding: 13px 16px;\n}\n\n.ViewWhere_amountColHead__1nWdL th> span{\n  display: flex;\n  grid-gap: 1ch;\n  gap: 1ch;\n  justify-content: start;\n  align-items: center;\n  position: relative;\n  overflow: visible;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  font-size: 11px;\n}\n.ViewWhere_amountColHead__1nWdL th> span>span{\n background-color: #e4fdfa;\n width: 3ch;\n border-radius: 50%;\n aspect-ratio: 1/1;\n color: #348888;\n border: 1px solid #348888;\n display: grid;\n place-content: center;\n position: absolute;\n right: -4ch;\n font-size: 11px;\n}\n\n@media (min-width: 1750px){\n  .ViewWhere_amountColHead__1nWdL th> span{\n    font-size: 13px;\n  }\n  .ViewWhere_amountColHead__1nWdL th> span>span{\n   font-size: 13px;\n  }\n}\n\n.ViewWhere_heightContainerWheniWhere__2mh5U{\n  height:100%;\n  max-height: 800px;\n  overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"options": "ViewWhere_options__3UZcZ",
	"amountColHead": "ViewWhere_amountColHead__1nWdL",
	"heightContainerWheniWhere": "ViewWhere_heightContainerWheniWhere__2mh5U"
};
module.exports = exports;
