import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ViewConfig, ViewWhen, ViewWhere, ViewConditions, ViewActions } from './Views'
import { Loading } from '../../components/PromoWizard/Steps/ActionsForm/components'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import MainNavbar from 'components/Navbars/MainNavbar'
import { getPromo } from 'api/PromotionsAPI'
import style from './ViewPromo.module.css'
import { ViewHeader } from './Components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ViewPromo = props => {
  const [originalPromo, setOriginalPromo] = useState(null)
  const [viewWizard, setViewWizard] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const generateWizzar = params => {
    return [
      { value: 'config', label: 'Configuración', Component: ViewConfig, data: params.header },
      {
        value: 'when',
        label: 'Cúando',
        Component: ViewWhen,
        data: params.body.activations,
        className: style['fifty-percent'],
      },
      {
        value: 'where',
        label: 'Donde',
        Component: ViewWhere,
        data: params.body.origins,
        className: style['fifty-percent'],
      },
      { value: 'conditions', label: 'Condiciones', Component: ViewConditions, data: params.body.rules },
      { value: 'actions', label: 'Acciones', Component: ViewActions, data: params.body.actions },
    ]
  }

  useEffect(() => {
    if (props.location?.state && props.location.state.promoId) {
      getPromo(props.location.state.promoId)
        .then(({ data }) => {
          setOriginalPromo(JSON.parse(JSON.stringify(data)))
          setLoading(false)
        })
        .catch(err => console.log(err))
    } else {
      setLoading(false)
    }
  }, [props.location?.state])

  useEffect(() => {
    if (!originalPromo) return

    setViewWizard(generateWizzar(originalPromo))
  }, [originalPromo])

  const renderComponent = ({ Component, className, data, ...restOfValues }, idx) => (
    <div className={`${style.panel} ${className ?? ''}`} key={idx}>
      <ViewHeader {...restOfValues} />
      {Component && <Component values={data} />}
    </div>
  );
  
  return (
    <section className={style.section}>
      <MainNavbar className="main-panel" />
      {loading ? (
        <Loading view />
      ) : (
        <>
          <span className={style.promoDetailHeaderTop} />
          <div className={style.promoDetailHeader}>
            <i className="icon" onClick={() => history.push({ pathname: '/' })
            }>
              <FontAwesomeIcon icon={faArrowLeft} />
            </i>
            <span>Detalle <span>/ Código {originalPromo.header.code}</span></span>
          </div>
          {viewWizard.length > 0 && renderComponent(viewWizard[0], 0)}
          <div className={style.containerWheniWhere}>
            {viewWizard.slice(1, 3).map(renderComponent)}
          </div>
          {viewWizard.slice(3).map(renderComponent)}
        </>
      )}
    </section>
  )
}

export default React.memo(withRouter(ViewPromo))
