// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewSearch_container__2Hg1z {\n  position: relative;\n}\n\n.ViewSearch_svg-container__3AhmV {\n  position: absolute;\n  z-index: 1;\n  width: 25px;\n  height: 100%;\n  display: grid;\n  cursor: pointer;\n  margin-left: 10px;\n  place-content: center;\n}\n\n.ViewSearch_svg-container__3AhmV > img {\n  width: 21px;\n  height: 21px;\n  object-fit: contain;\n  object-position: center;\n}\n\n.ViewSearch_input__bEV6- {\n  outline: 0;\n  width: 50px;\n  background: #fff;\n  border-radius: 25px;\n  padding: 11px 22px 11px 40px;\n  caret-color: var(--primary-color);\n  color: var(--Gris-100, #68688c);\n  font: normal 500 16px/137.5% Inter;\n  border: 1px solid var(--Gris-20, #ccd1e4);\n  box-shadow: 0px 0px 4px 0px rgba(0, 34, 171, 0.15);\n  transition: 0.3s ease-out;\n}\n\n.ViewSearch_input__bEV6-::-webkit-input-placeholder {\n  color: var(--Gris-50, #a1a9c7);\n  font: normal 500 16px/137.5% Inter;\n}\n\n.ViewSearch_input__bEV6-::-ms-input-placeholder {\n  color: var(--Gris-50, #a1a9c7);\n  font: normal 500 16px/137.5% Inter;\n}\n\n.ViewSearch_input__bEV6-::placeholder {\n  color: var(--Gris-50, #a1a9c7);\n  font: normal 500 16px/137.5% Inter;\n}\n\n.ViewSearch_input__bEV6-.ViewSearch_true__1J-nV {\n  width: 325px;\n  background: #fff;\n  border-color: var(--Gris-20, #ccd1e4);\n  animation: ViewSearch_inputAnimated__1Ew3j 0.3s ease-out forwards;\n  box-shadow: 0px 0px 4px 0px rgba(0, 34, 171, 0.15);\n}\n\n.ViewSearch_input__bEV6-.ViewSearch_false__16Ldf {\n  width: 50px;\n  background: transparent;\n  border-color: transparent;\n  box-shadow: 0px 0px 4px 0px transparent;\n}\n\n.ViewSearch_input__bEV6-.ViewSearch_false__16Ldf:is(:hover, :focus, :active, :focus-within) {\n  border: 1px solid transparent !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ViewSearch_container__2Hg1z",
	"svg-container": "ViewSearch_svg-container__3AhmV",
	"input": "ViewSearch_input__bEV6-",
	"true": "ViewSearch_true__1J-nV",
	"inputAnimated": "ViewSearch_inputAnimated__1Ew3j",
	"false": "ViewSearch_false__16Ldf"
};
module.exports = exports;
