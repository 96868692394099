// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Body_body__1cPIN > tr:last-child {\n  border-bottom: unset;\n  margin-bottom: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"body": "Body_body__1cPIN"
};
module.exports = exports;
