import React from 'react'

import style from './ViewExplain.module.css'
import { Cell } from '../ViewTable'

import icon from './Assets/icon.svg'

const ViewExplain = ({ title, desc, cell = true }) => {
  if (!cell) {
    return (
      title &&
      desc && (
        <div className={style.container}>
          <div className={style.icon}>
            <img src={icon} alt="icon" draggable={false} loading="lazy" />
            <div className={style.tooltip}>
              <h4>{title}</h4>
              <p>{desc}</p>
            </div>
          </div>
        </div>
      )
    )
  }

  return (
    <Cell className={style.cell}>
      {title && desc && (
        <div className={style.container}>
          <div className={style.icon}>
            <img src={icon} alt="icon" draggable={false} loading="lazy" />
            <div className={style.tooltip}>
              <h4>{title}</h4>
              <p>{desc}</p>
            </div>
          </div>
        </div>
      )}
    </Cell>
  )
}

export default ViewExplain
