import { useTranslation } from 'react-i18next'
import React from 'react'

import { Table, Head, Body, Cell, Row } from 'views/ViewPromo/Components/ViewTable'
import { ViewTag } from 'views/ViewPromo/Components'
import { formatTimestampToDate, normalizeString } from '../../Utils'
import style from "../ViewWhere/ViewWhere.module.css"
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'
const ViewWhen = ({ values }) => {
  const { t } = useTranslation('viewPromo')

  const getDates = param => {
    if (param.specific_day) {
      return formatTimestampToDate(param.specific_day)
    }
  
    if (param.days?.length === 7) {
      return 'Todos los días'
    }
  
    if (param.day_month) {
      return `Día ${param.day_month} del mes`
    }
  
    return param.days?.map((day, idx) => `${t(`when.days.${day}`)}${param.days.length !== idx + 1 ? ', ' : ''}`).join('')
  }

  const getTime = param => {
    if (param.all_day) {
      return 'Todo el día'
    }
  
    return `${param.time_from} a ${param.time_to}`
  }

  return (
    <Table className={`${style['heightContainerWheniWhere']}`}
      search={true}
      renderSearchValue={(searchValue) => {
        const filteredValues = values?.filter(({ date_setting, time_range }) => 
          normalizeString(getTime(time_range)).toLowerCase().includes(normalizeString(searchValue).toLowerCase()) || 
          normalizeString(getDates(date_setting)).toLowerCase().includes(normalizeString(searchValue).toLowerCase())
        )      
      return (
          <>
            <Head
              view={'when'}
              columns={[
                { value: 'horary' },
                { value: 'days' },
                { value: <ViewTag label={`${values?.length} opciones seleccionadas`} color={'#F7F7FF'} /> },
              ]}
            />
          <Body>
            {filteredValues.length > 0 ? filteredValues?.map(({ id, date_setting, time_range }) => (
              <Row key={id}>
                <Cell>{getTime(time_range)}</Cell>
                <Cell>{getDates(date_setting)}</Cell>
              </Row>
            )) : (
              <Row>
                <Cell colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                  <EmptyState />
                </Cell>
              </Row>
            )
            }
          </Body>
          </>
        )
      }}
    />
  )
}

export default ViewWhen