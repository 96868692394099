import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatTimestampToDate, getAccumulationValue } from '../../Utils'
import { ViewTag, ViewCard, DetailedViewCard, ViewLoader } from '../../Components'
import StatusBadge from '../../../../components/common/StatusBadge/StatusBadge'
import calendarySVG from './Assets/calendary.svg'
import style from './ViewConfig.module.css'
import { getPriceLists } from 'api/ListAPI'

const ViewConfig = ({ values }) => {
  const [pricesLists, setPricesLists] = useState([])
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])

  const status =
    moment().isAfter(moment(values.date_time_to)) && !values.always_current
      ? 'expired'
      : values.paused
      ? 'paused'
      : 'active'


  const fetchPricesList = () => {
    const promise = getPriceLists()
      .then(({ data }) => {
        console.log("_____________________________________________________________________________-")
        console.log(data)

        setPricesLists(data)
      })
      .catch(err => {
        setPricesLists([{ label: 'No se pudo recuperar las listas', value: err }])
      })
    return promise
  }

  useEffect(() => {
    if (values.excluded_lists && values.excluded_lists.length !== 0) {
      fetchPricesList()
    }
  }, [values.excluded_lists])

  useEffect(() => {
    if (pricesLists?.length !== 0 && values.excluded_lists?.length !== 0) {
      console.log("pricesLists",pricesLists)
      console.log("values.excluded_lists",values.excluded_lists)
      setList(
        pricesLists
          .filter(priceList => values.excluded_lists.find(list => priceList.value == list))
          ?.map(item => item.label)
      )
      setList(
        values.excluded_lists
          .map(item => pricesLists.find(list => list.value == item)?.label || "")
          .filter(label => label !== "")
      )
      setLoading(false)
    }
  }, [pricesLists, values.excluded_lists])

  return (
    <ViewCard className={style.container} color={'#fff'}>
      <div className={style['container-description']}>
        <h4>{values.name}</h4>
        <div className={style.description}>
          <div className={style.text}>
            {values.description.split('\n').map((line, index) => (
              <p key={index}>
                {line}
                <br />
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={style['container-details']}>
        <DetailedViewCard className={style['container-date']}>
          <div className={style['container-date-svg']}>
            <img src={calendarySVG} alt="icono de calendario" />
          </div>
          <div className={style['container-date-text']}>
            {
              values.always_current ?
              <p>
              <strong> Siempre vigente </strong>
            </p>
            :
            <>
              <p>
                Inicio
                <strong> {formatTimestampToDate(values.date_time_from)}</strong>
              </p>
              <p>
                Finaliza
                <strong> {formatTimestampToDate(values.date_time_to)}</strong>
              </p>
            </>
            }
          </div>
          <StatusBadge 
            status={status} 
            pill 
            outline 
            className={style['container-status-badge']} 
            />
        </DetailedViewCard>
        <DetailedViewCard className={style['container-price-list']}>
          <div className={style['container-price-list-title']}>
            {values.excluded_lists?.length !== 0 ? (
              <h4>Listas de precios excluidas</h4>
            ) : (
              <div>
                <span>Sin lista de precios excluidas</span>
              </div>
            )}
            <ViewTag
              className={style['not-acumulable-manual-discount']}
              label={
                values.not_acumulable_manual_discount ? 'No acumulable con descuentos' : 'Acumulable con descuentos'
              }
              color={'#F7F7FF'}
            />
          </div>
          {values.excluded_lists?.length !== 0 && (
            <div className={style['container-price-list-content']}>
              {loading ? (
                <ViewLoader />
              ) : (
                list?.map((item, idx) => <ViewTag label={item} key={idx} color={'#E4FDFA'} />)
              )}
            </div>
          )}
        </DetailedViewCard>
        <DetailedViewCard className={style['container-maximum-application-amount']}>
          {!values.limit_per_voucher ? (
            <div style={{ padding: '0', textAlign: 'start' }}>
              <span>Sin limite de aplicación</span>
            </div>
          ) : (
            <div className={style['content']}>
              <div>
                <span>{`${values.limit_per_voucher} por comprobante`}</span>
                <p>Cantidad máxima de aplicación</p>
              </div>
            </div>
          )}
          <ViewTag
            className={style['not-acumulable-manual-discount']}
            label={getAccumulationValue(values.not_acumulable)}
            color={'#F7F7FF'}
          />
        </DetailedViewCard>
      </div>
    </ViewCard>
  )
}

export default ViewConfig
