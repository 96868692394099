import React from 'react'

import style from './Row.module.css'

const Row = ({ id, styles, children, withArrow, className, onClickRow, onClickArrow }) => {
  return (
    <tr className={`${style.row} ${className || ''}`} style={styles} onClick={onClickRow} data-id={id}>
      {children}
      {withArrow ? (
        <td>
          <div className={style.arrowContainer}>
            <button
              type="button"
              onClick={e => {
                e.stopPropagation()
                if (onClickArrow) {
                  onClickArrow()
                }
              }}
              id={`table-head-row-button-arrow-${id}`}>
              <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.97161 1.07586L9.39289 1.49714L5.3 5.59003L1.20711 1.49714L1.62839 1.07586L4.94683 4.38708L5.3 4.73948L5.65317 4.38708L8.97161 1.07586Z"
                  fill="#68688C"
                  stroke="#68688C"
                />
              </svg>
            </button>
          </div>
        </td>
      ) : (
        <td className={style.withoutArrow}></td>
      )}
    </tr>
  )
}

export default Row
