import React from 'react'

import style from './ViewCard.module.css'

const ViewCard = ({ children, color, className }) => {
  return (
    children && (
      <div style={color ? { '--bg-color': `${color}` } : undefined} className={`${style.card} ${className ?? ''}`}>
        {children}
      </div>
    )
  )
}

export default ViewCard
