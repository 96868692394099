const formatTimestampToDate = timestamp => {
  const date = new Date(timestamp)
  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear()

  return `${day}/${month}/${year}`
}

const isPromotionExpired = endTimestamp => {
  const now = Date.now()
  return endTimestamp < now
}

const getAccumulationValue = value => {
  switch (value) {
    case 0:
      return 'Promoción acumulable con otras promociones'
    case 1:
      return 'No acumulable con promociones del mismo criterio'
    case 2:
      return 'No acumulable con otras promociones'
  }
}


  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

export { formatTimestampToDate, isPromotionExpired, getAccumulationValue, normalizeString }
