import React, { useState, useEffect } from 'react'
import { normalizeString } from 'views/ViewPromo/Utils'
import { customerEntities } from '../../../../../../components/PromoWizard/Steps/ConditionsForm/variables'
import { Table, Head, Body, Row, Cell } from '../../../../Components/ViewTable'
import { ViewTag } from 'views/ViewPromo/Components'
import style from '../../ViewConditions.module.css'
import { getEntities } from 'api/EntitiesAPI'
import i18n from '../../../../../../i18n'
import { InnerTable } from '..'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const ClientsTable = ({ clientInfo, className = '' }) => {
  const [fetchedEntities, setFetchedEntities] = useState({})
  const [clientOpen, setClientOpen] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [values, setValues] = useState([])

  const changeClientOpen = id => {
    setClientOpen(prev => (prev === id ? undefined : id))
  }

  const removeDuplicates = data => {
    const uniqueEntries = new Map()

    data.forEach(item => {
      const key = `${item.rule_id}-${item.entity_id}`
      if (!uniqueEntries.has(key)) {
        uniqueEntries.set(key, item)
      }
    })

    return Array.from(uniqueEntries.values())
  }

  useEffect(() => {
    setValues(
      clientInfo.map(item => ({
        ...item,
        entity_details: removeDuplicates(item.entity_details),
        entity: i18n.t(`${customerEntities.find(({ value }) => item.entity === value).name}`),
        endpoint: customerEntities.find(({ value }) => item.entity === value).endpoint,
      }))
    )
  }, [clientInfo])

  useEffect(() => {
    const fetchData = async (endpoint, entity_details) => {
      setLoading(true)
      const response = await getEntities(
        `${endpoint}/ids?ids=${entity_details.map(element => element.entity_id).join(',')}`
      )
      setLoading(false)

      setFetchedEntities(prev => ({
        ...prev,
        [clientOpen]: response.data.map(({ id, description }) => ({
          id,
          label: description,
        })),
      }))

      setOptions(
        response.data.map(({ id, description }) => ({
          id,
          label: description,
        }))
      )
    }

    if (clientOpen !== undefined) {
      const { endpoint, entity_details } = values.find(item => item.id === clientOpen)

      if (fetchedEntities[clientOpen]) {
        setOptions(fetchedEntities[clientOpen])
      } else {
        fetchData(endpoint, entity_details)
      }
    }
  }, [clientOpen, fetchedEntities, values])

  return (
    clientInfo?.length !== 0 && (
      <Table className={className} entity={'Cliente'}
            search={true}
        renderSearchValue={(searchValue) => {
          const valueFiltered = values.filter(({ entity }) => normalizeString(entity).toLowerCase().includes(normalizeString(searchValue.toLowerCase())))
          return (
            <>
              <Head view={'conditions.clients'} columns={[{ value: 'attributes' }, { value: 'options' }]} />
              <Body>
                {valueFiltered.length > 0 ? valueFiltered.map(({ id, entity, entity_details }) => (
                  <React.Fragment key={id}>
                    <Row
                      id={id}
                      withArrow
                      onClickArrow={() => changeClientOpen(id)}
                      className={`${clientOpen === id ? style.isOpen : ''}`}>
                      <Cell className={style.bolder}>{entity}</Cell>
                      <Cell>
                        <ViewTag color={'rgba(228, 253, 250, 1)'} label={`${entity_details?.length} Seleccionados`} />
                      </Cell>
                    </Row>
                    <InnerTable
                      className={style.clientsInner}
                      colSpan={3}
                      name={'conditions.clients'}
                      itemOpen={clientOpen === id}
                      columns={['article', 'clients']}
                      loading={clientOpen === id && loading}>
                      {options.map(({ id, label }) => (
                        <Row key={id}>
                          <Cell>
                            <span>{id}</span>
                          </Cell>
                          <Cell styles={{
                            paddingLeft: '.5ch'
                          }}>
                            {label}
                          </Cell>
                        </Row>
                      ))}
                    </InnerTable>
                  </React.Fragment>
                )) :
                  (
                    <Row>
                      <Cell colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                        <EmptyState />
                      </Cell>
                    </Row>
                  )}
              </Body>
            </>
          )
        }}
      />
    )
  )
}

export default ClientsTable
