// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GeneralDiscount_isOpen__2Sxr7 > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n", ""]);
// Exports
exports.locals = {
	"isOpen": "GeneralDiscount_isOpen__2Sxr7"
};
module.exports = exports;
