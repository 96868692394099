// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewActions_container__39FEI {\n  width: 100%;\n  grid-gap: 0.75rem;\n  gap: 0.75rem;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-top: 0.5rem;\n}\n\n@media (min-width: 1300px) {\n  .ViewActions_fifty-percent__2w54u {\n    max-width: calc(50% - 0.75rem);\n  }\n}\n\n.ViewActions_discount__3iyHV thead th:nth-last-child(2),\n.ViewActions_discount__3iyHV thead th:nth-last-child(3),\n.ViewActions_discount__3iyHV tbody td:nth-last-child(2),\n.ViewActions_discount__3iyHV tbody td:nth-last-child(3){\n  text-align: right;\n}\n\n\n.ViewActions_general-discount__1WklD thead th:nth-last-child(2),\n.ViewActions_general-discount__1WklD thead th:nth-last-child(3),\n.ViewActions_general-discount__1WklD tbody td:nth-last-child(2),\n.ViewActions_general-discount__1WklD tbody td:nth-last-child(3){\n  text-align: right;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ViewActions_container__39FEI",
	"fifty-percent": "ViewActions_fifty-percent__2w54u",
	"discount": "ViewActions_discount__3iyHV",
	"general-discount": "ViewActions_general-discount__1WklD"
};
module.exports = exports;
