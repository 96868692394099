// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaymentsContent_details-container-row-header__38oST > td {\n  max-width: 100%;\n  text-align: start;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font: normal 500 14px/150% Inter;\n  text-transform: uppercase;\n  color: #68688c;\n}\n\n.PaymentsContent_details-container-row-header__38oST {\n  border-block: 1px solid #ccd1e4 !important;\n}\n\n.PaymentsContent_details-container-row-header__38oST:first-child {\n  border-top: unset !important;\n}\n\n.PaymentsContent_details-container__1Jhu6 {\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 12px;\n  gap: 12px;\n}\n\n.PaymentsContent_details-container-card__3XmbO > div {\n  border-radius: 4px;\n}\n\n.PaymentsContent_details-container-card__3XmbO {\n  padding: 2px 8px;\n}\n\n.PaymentsContent_fees__3gcO9 {\n  grid-gap: 12px;\n  gap: 12px;\n  display: flex;\n  flex-wrap: wrap;\n}\n", ""]);
// Exports
exports.locals = {
	"details-container-row-header": "PaymentsContent_details-container-row-header__38oST",
	"details-container": "PaymentsContent_details-container__1Jhu6",
	"details-container-card": "PaymentsContent_details-container-card__3XmbO",
	"fees": "PaymentsContent_fees__3gcO9"
};
module.exports = exports;
