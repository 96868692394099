import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { paymentMethodTypesArgExclusive } from '../../../../../../../../components/PromoWizard/Steps/ActionsForm/variables'
import { ViewTag, ViewLoader } from '../../../../../../Components'
import { Row, Cell } from '../../../../../../Components/ViewTable'
import { getEntities } from 'api/EntitiesAPI'
import style from './PaymentsContent.module.css'

const loader = () => {
  return (
    <Row>
      <Cell>
        <ViewLoader small />
      </Cell>
    </Row>
  )
}

const PaymentsContent = ({ payments, open, page }) => {
  const [formattedPayments, setFormattedPayments] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('viewPromo')

  const getColumns = type => {
    switch (type) {
      case 'CASH':
        return ['cash']
      case 'CREDIT_CARD':
        return ['card', 'fees-selected']
      case 'INTERDEPOSIT':
        return ['interdeposit']
      case 'PAGO_QR':
        return ['qr']
      default:
        return []
    }
  }

  const getColSpan = type => (type === 'CREDIT_CARD' ? [ page === "set-price" ? 2 : 1 , page === 'general' ? 5 :3] : [2, 2])

  const groupBy = (arr, key) => {
    const grouped = []

    arr.forEach(item => {
      if (item.type_entity_id === key) {
        const groupedItem = grouped.find(({ type_entity_id }) => type_entity_id === key)

        if (groupedItem) {
          groupedItem.entity_id = [...groupedItem.entity_id, ...item.entity_id]
          groupedItem.fees = [...groupedItem.fees, { entity_id: item.entity_id[0], fees: item.fees }]
          groupedItem.details = [...groupedItem.details, ...item.details]

          return
        }

        return grouped.push({
          type_entity_id: item.type_entity_id,
          entity_id: [...item.entity_id],
          fees: [{ entity_id: item.entity_id[0], fees: item.fees }],
          columns: item.columns,
          details: [...item.details],
        })
      }

      grouped.push(item)
    })

    return grouped
  }

  const fetchPayments = async paymentMethods => {
    if (!paymentMethods || paymentMethods.length === 0) return

    const promises = paymentMethods.map(async ({ endpoint, ...rest }) => {
      const { data: { content } = {} } = await getEntities(endpoint)

      return {
        ...rest,
        columns: getColumns(rest.type_entity_id),
        details: rest.entity_id.map(id => content.find(item => item.id === id)),
      }
    })

    const results = await Promise.all(promises)

    setFormattedPayments(groupBy(results, 'CREDIT_CARD'))
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      const paymentsWithEndpoints = payments.map(payment => ({
        ...payment,
        endpoint: paymentMethodTypesArgExclusive.find(item => item.value === payment.type_entity_id)?.endpoint,
      }))

      fetchPayments(paymentsWithEndpoints)
    }
  }, [payments, open])

  if (loading) {
    return loader()
  }

  return formattedPayments.map(({ type_entity_id, details, columns, fees }) => (
    <React.Fragment key={type_entity_id}>
      <Row className={style['details-container-row-header']}>
        {columns.map((col, idx) => (
          <Cell key={`${col}-${idx}`} colSpan={getColSpan(type_entity_id)[idx]} styles={{paddingLeft: page === 'discount' && idx === 1 ? '6%' : (page === 'set-price' && idx === 1 ? '27%' : '.5%' )}}>
            {t(`actions.discount.innerTable.payment_method.${col}`)}
          </Cell>
        ))}
      </Row>
      {type_entity_id !== 'CREDIT_CARD' && (
        <Row>
          <Cell colSpan={getColSpan(type_entity_id)}>
            <div className={style['details-container']}>
              {details?.map(({ id, description }) => (
                <ViewTag
                  key={id}
                  label={description}
                  fontColor="#68688C"
                  color="transparent"
                  className={style['details-container-card']}
                  borderColor="#A1A9C7"
                />
              ))}
            </div>
          </Cell>
        </Row>
      )}
      {type_entity_id === 'CREDIT_CARD' &&
        details.map(({ id, description }) => (
          <Row key={`${type_entity_id}-${id}`}>
            <Cell colSpan={page === "set-price" ? 2 : undefined}>
              <ViewTag
                key={id}
                label={description}
                fontColor="#68688C"
                color="transparent"
                className={style['details-container-card']}
                borderColor="#A1A9C7"
              />
            </Cell>
            <Cell colSpan={page === "set-price" ? 3 : 2}>
              <div className={style.fees} style={{
                paddingLeft: page === 'discount' ? '9%' : (page === 'set-price' ? '27%' : '2%')
              }}>
                {fees
                  .find(({ entity_id }) => entity_id === id)
                  .fees.sort((a, b) => a - b)
                  .map(fee => (
                    <ViewTag
                      label={fee}
                      key={`${id}-${fee}`}
                      color={'transparent'}
                      fontColor={'#68688C'}
                      borderColor={'#A1A9C7'}
                    />
                  ))}
              </div>
            </Cell>
          </Row>
        ))}
    </React.Fragment>
  ))
}

export default PaymentsContent
