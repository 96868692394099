import { Label, FormGroup } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BaseSelect from 'react-select'

import FixRequiredSelect from 'components/common/RequiredSelect'
import styles from './SelectWithTooltip.module.css'
import infoSVG from '../../assets/info.svg'

export const Select = props => <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />

const SelectWithTooltip = ({ name, label, value, options, onChange, children }) => {
  const { t } = useTranslation('createPromo')

  return (
    <FormGroup>
      <div className={styles.container}>
        <div className={styles.content}>
          <span className={styles.span}>{label}</span>
          <div className={styles.tooltipContainer}>
            <img src={infoSVG} className={styles.svg} />
            <div className={styles.tooltip}>{children}</div>
          </div>
        </div>
        {options?.length <= 0 ? (
          <p>Cargando opciones...</p>
        ) : (
          <Select
            isSearchable={false}
            className="form-control p-0"
            classNamePrefix="react-select"
            name={name || 'selection'}
            value={options?.find(option => option.value === String(value)) || null}
            onChange={onChange}
            options={options}
            loadingMessage={() => `${t('steps.settings.form.loading')}`}
            noOptionsMessage={() => t('steps.settings.form.no_results')}
            placeholder={'Seleccionar criterio'}
            required
            styles={{
              container: baseStyles => ({
                ...baseStyles,
              }),
              control: baseStyles => ({
                ...baseStyles,
                cursor: 'pointer',
              }),
              option: baseStyles => ({
                ...baseStyles,
                cursor: 'pointer',
                fontWeight: 'bolder',
              }),
            }}
          />
        )}
      </div>
    </FormGroup>
  )
}

export default SelectWithTooltip
