// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ReceiptTable_isOpen__1x5O3 > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.ReceiptTable_colored__2gK0W > div {\n  color: #ff7d00;\n}\n\n.ReceiptTable_colored__2gK0W > div > span {\n  color: #ff7d00;\n}\n\n.ReceiptTable_fees__1F0p4 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  grid-gap: 12px;\n  gap: 12px;\n}\n\n.ReceiptTable_card-tag__2hMVl > div {\n  border-radius: 4px;\n}\n\n.ReceiptTable_align-right__3-l9E{\n  text-align: right;\n}\n\n@media (min-width: 1300px) {\n  .ReceiptTable_fifty-percent__22CLP {\n    max-width: calc(50% - 0.75rem);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"isOpen": "ReceiptTable_isOpen__1x5O3",
	"colored": "ReceiptTable_colored__2gK0W",
	"fees": "ReceiptTable_fees__1F0p4",
	"card-tag": "ReceiptTable_card-tag__2hMVl",
	"align-right": "ReceiptTable_align-right__3-l9E",
	"fifty-percent": "ReceiptTable_fifty-percent__22CLP"
};
module.exports = exports;
