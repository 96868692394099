// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Row_row__iFe-H {\n  width: 100%;\n  border-bottom: 1px solid #ccd1e4;\n}\n\n.Row_row__iFe-H > td:last-child {\n  position: relative;\n  width: 30px;\n  height: 100%;\n}\n\n.Row_arrowContainer__2pBFk {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: grid;\n  place-items: center;\n}\n\n.Row_arrowContainer__2pBFk > button {\n  width: 21px;\n  height: 21px;\n  background: 0;\n  outline: 0;\n  border: 0;\n  cursor: pointer;\n}\n\n.Row_arrowContainer__2pBFk > button > svg {\n  rotate: 0deg;\n  transition: ease-out 0.3s;\n}\n\n.Row_arrowContainer__2pBFk > button svg path {\n  transition: ease 0.3s;\n}\n\n.Row_arrowContainer__2pBFk > button:is(:hover, :active) svg path {\n  fill: var(--primary-color);\n  stroke: var(--primary-color);\n}\n\n.Row_withoutArrow__3h-VJ {\n  padding-right: 4rem;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "Row_row__iFe-H",
	"arrowContainer": "Row_arrowContainer__2pBFk",
	"withoutArrow": "Row_withoutArrow__3h-VJ"
};
module.exports = exports;
