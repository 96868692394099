// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewConditions_container__3X4Ax {\n  width: 100%;\n  grid-gap: 0.75rem;\n  gap: 0.75rem;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-top: 0.5rem;\n}\n\n.ViewConditions_isOpen__3gvQM > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.ViewConditions_bolder__bF7aO span{\n  color: var(--Gris-200, #303048);\n  font-weight: bolder;\n}\n\n.ViewConditions_clientsInner__2ruE0 tr:first-of-type th:nth-of-type(2){\n  padding-left: 3ch;\n}\n\n@media (min-width: 1300px) {\n  .ViewConditions_fifty-percent__2Vhs3 {\n    max-width: calc(50% - 0.75rem);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ViewConditions_container__3X4Ax",
	"isOpen": "ViewConditions_isOpen__3gvQM",
	"bolder": "ViewConditions_bolder__bF7aO",
	"clientsInner": "ViewConditions_clientsInner__2ruE0",
	"fifty-percent": "ViewConditions_fifty-percent__2Vhs3"
};
module.exports = exports;
