import React from 'react'
import style from './ViewActions.module.css'

import { Discount, GeneralDiscount, ProductBonus, SetPrice } from './Components'

const ActionWizard = [
  { value: 'product_bonus', label: 'Bonificar Producto', className: style['fifty-percent'], Component: ProductBonus },
  { value: 'set_price', label: 'Fijar Precio', className: style['fifty-percent'], Component: SetPrice },
  { value: 'discount', label: 'Descuento', Component: Discount, className: style['discount'] },
  { value: 'general_discount', label: 'Descuento General', Component: GeneralDiscount, className: style['general-discount'] },
]

const ViewActions = ({ values }) => {
  const groupedData = values.reduce((acc, item) => {
    const key = item.type.toLowerCase()
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(item)
    return acc
  }, {})

  return (
    <div className={style.container}>
      {ActionWizard.map(({ value, Component, className }) => {
        const data = groupedData[value]
        if (!data) return null

        return <Component key={value} data={data} className={className} value={value} />
      })}
    </div>
  )
}

export default ViewActions
