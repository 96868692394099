import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { paymentMethodTypesArgExclusive } from '../../../../../../../../components/PromoWizard/Steps/ActionsForm/variables'
import { ViewTag, ViewLoader } from '../../../../../../Components'
import { Row, Cell } from '../../../../../../Components/ViewTable'
import { getEntities } from 'api/EntitiesAPI'
import style from './AttributesContent.module.css'
import { productEntities } from 'components/PromoWizard/Steps/ConditionsForm/variables'

const loader = () => {
  return (
    <Row>
      <Cell>
        <ViewLoader small />
      </Cell>
    </Row>
  )
}

const AttributesContent = ({ open, productsInfo }) => {
  const [formattedAttributes, setFormattedAttributes] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('viewPromo')

  const fetchAttribute = async attributesMethods => {
    if (!attributesMethods || attributesMethods.length === 0) return 

    const promises = attributesMethods.map(async ({ endpoint, ...rest }) => {
      const { data: { content } = {} } = await getEntities(endpoint)
      console.log(rest)
     return {
        ...rest,
        columns: rest.name,
        details: rest.ids.map(id => content.find(item => item.id === id)),
      } 
    })

    const results = await Promise.all(promises)
    console.log(results)
    setFormattedAttributes(results)
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      const attributes = productsInfo.map(atribute => {
        const fined = productEntities.find(item => item.value === atribute.entity)
        const resp =
        {
          ...atribute,
          endpoint: fined?.endpoint,
          name: fined?.name
        }
        return resp
      })
      console.log(attributes)
      fetchAttribute(attributes)
    }
  }, [productsInfo, open])

  if (loading) {
    return loader()
  }

  return (<>
  {formattedAttributes.map(({ name, details, columns }) => (
    <React.Fragment key={name}>
      <Row className={style['details-container-row-header']}>
        {columns &&
          <Cell key={`${columns}`} colSpan={2}>
            {t(columns)}
          </Cell>
        }
      </Row>
        <Row>
          <Cell colSpan={2}>
            <div className={style['details-container']}>
              {details?.map(({ id, description }) => (
                <ViewTag
                  key={id}
                  label={description}
                  fontColor="#68688C"
                  color="transparent"
                  className={style['details-container-card']}
                  borderColor="#A1A9C7"
                />
              ))}
            </div>
          </Cell>
        </Row>
    </React.Fragment>
  ))
  }
  </>
  )
}

export default AttributesContent
