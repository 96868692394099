// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductsTable_isOpen__18o4a > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.ProductsTable_aling-right__1akvj {\n  text-align: right;\n}\n\n.ProductsTable_innerTableProducts__32R2M tr th:last-of-type{\n  width: 72%;\n}\n\n.ProductsTable_bolder__iX9P7 span{\n  color: var(--Gris-200, #303048);\n  font-weight: bolder;\n}\n\n \n.ProductsTable_attributes-col__2FdkR{\n  width: 23%;\n}\n\n.ProductsTable_value-col__nqTZy{\n  width: 28%;\n  text-align: right;\n}\n\n\n@media (min-width: 1700px){\n  .ProductsTable_innerTableProducts__32R2M tr th:last-of-type{\n    width: 73%;\n  }\n  .ProductsTable_attributes-col__2FdkR{\n    width: 23.5%;\n  }\n  .ProductsTable_value-col__nqTZy{\n    width: 30%;\n  }\n} \n\n@media (min-width: 2400px){\n  .ProductsTable_innerTableProducts__32R2M tr th:last-of-type{\n    width: 74.1%;\n  }\n  .ProductsTable_value-col__nqTZy{\n    width: 32%;\n  }\n} ", ""]);
// Exports
exports.locals = {
	"isOpen": "ProductsTable_isOpen__18o4a",
	"aling-right": "ProductsTable_aling-right__1akvj",
	"innerTableProducts": "ProductsTable_innerTableProducts__32R2M",
	"bolder": "ProductsTable_bolder__iX9P7",
	"attributes-col": "ProductsTable_attributes-col__2FdkR",
	"value-col": "ProductsTable_value-col__nqTZy"
};
module.exports = exports;
