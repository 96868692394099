import React, { useRef, useState } from 'react'

import style from './ViewSearch.module.css'

import search from './Assets/ViewSearch.svg'

const ViewSearch = ({ view,setSearchValueProp }) => {
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const searchRef=useRef()

  const toggleInput = () => {
    if (searchValue === '') {
      setIsOpen(!isOpen)
      if(!isOpen)
        searchRef.current.focus()
    }
  }

  const handleChange = e => {
    if (!isOpen) return
    setSearchValue(e.target.value)
    setSearchValueProp(e.target.value)
  }

  return (
    <div className={style.container}>
      <div className={style['svg-container']} onClick={toggleInput}>
        <img src={search} alt="search" draggable={false} loading="lazy" />
      </div>
      <input
        type="text"
        ref={searchRef}
        value={searchValue}
        placeholder="Buscar"
        id={`search-${view}`}
        name={`search-${view}`}
        onChange={handleChange}
        className={`${style.input} ${style[isOpen]}`}
      />
    </div>
  )
}

export default ViewSearch
