import { useTranslation } from 'react-i18next'
import React from 'react'

import style from './Head.module.css'
import { Row } from '..'

const Head = ({ columns, view }) => {
  const { t } = useTranslation('viewPromo')

  return (
    <thead className={style.thead}>
      <Row className={style.tr}>
        {columns.map(({ value, className }, idx) => (
          <th className={`${style.th} ${className ?? ''} `} key={`table-head-${view}-${idx}`}>
            {typeof value === 'string' ? <span className={style.span}>{t(`${view}.table.${value}`)}</span> : value}
          </th>
        ))}
      </Row>
    </thead>
  )
}

export default Head
