// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InnerTable_row-container__1SVfZ {\n  max-height: 300px;\n}\n.InnerTable_inner-table-container__LGZy6 {\n  max-height: 0px;\n  overflow-y: auto;\n  transition: ease 0.3s;\n  animation: InnerTable_reveal__M8Htr 0.3s forwards;\n  background: #f7f7ff;\n}\n\n.InnerTable_isLeaving__glza5 {\n  animation: InnerTable_hide__2pZPv 0.3s forwards;\n}\n\n@keyframes InnerTable_hide__2pZPv {\n  0% {\n    max-height: 300px;\n    opacity: 1;\n    visibility: visible;\n    transform: translateY(0px);\n  }\n  100% {\n    max-height: 0px;\n    opacity: 0;\n    visibility: hidden;\n    transform: translateY(10px);\n  }\n}\n\n@keyframes InnerTable_reveal__M8Htr {\n  0% {\n    max-height: 0px;\n    opacity: 0;\n    visibility: hidden;\n    transform: translateY(10px);\n  }\n  100% {\n    max-height: 300px;\n    opacity: 1;\n    visibility: visible;\n    transform: translateY(0px);\n  }\n}\n\n.InnerTable_table__2Uh_Y {\n  width: 100%;\n  table-layout: fixed;\n  border-collapse: collapse;\n  background: #f7f7ff;\n}\n\n.InnerTable_row__2n73g {\n  border: unset;\n}\n\n.InnerTable_row__2n73g > th {\n  padding: 16px 24px;\n}\n\n.InnerTable_table__2Uh_Y th span {\n    max-width: 100%;\n    text-align: start;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    font: normal 500 14px/150% Inter;\n    text-transform: uppercase;\n    color: #68688c;\n}\n\n.InnerTable_body__36xxc td > span {\n  max-width: 100%;\n  text-align: start;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  color: var(--Gris-100, #68688c);\n  font: normal 500 13px/175% Inter;\n  letter-spacing: 0.24px;\n}\n\n.InnerTable_body__36xxc > tr {\n  border: unset;\n}\n", ""]);
// Exports
exports.locals = {
	"row-container": "InnerTable_row-container__1SVfZ",
	"inner-table-container": "InnerTable_inner-table-container__LGZy6",
	"reveal": "InnerTable_reveal__M8Htr",
	"isLeaving": "InnerTable_isLeaving__glza5",
	"hide": "InnerTable_hide__2pZPv",
	"table": "InnerTable_table__2Uh_Y",
	"row": "InnerTable_row__2n73g",
	"body": "InnerTable_body__36xxc"
};
module.exports = exports;
