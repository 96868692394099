import React, { useState, useEffect } from 'react'

import { productEntities } from '../../../../../../components/PromoWizard/Steps/ConditionsForm/variables'
import { Table, Head, Body, Row, Cell } from '../../../../Components/ViewTable'
import { ViewLoader } from '../../../../Components'
import { getEntities } from 'api/EntitiesAPI'
import EmptyState from 'views/ViewPromo/Components/EmptyState/EmptyState'

const ProductBonus = ({ data, className, value }) => {
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(true)

  const transformData = (apiResponse, attributes) => {
    return attributes.map((item, index) => ({
      ...item,
      attribute: {
        value: apiResponse[index]?.id || item.attribute.value,
        label: apiResponse[index]?.description || 'Unknown',
      },
    }))
  }

  const fetchProducts = async (attributes, endpoint) => {
    if (!attributes || attributes.length === 0 || !endpoint) return

    const ids = attributes.map(item => item.attribute.value).join(',')

    try {
      const response = await getEntities(`${endpoint}/ids?ids=${ids}`)
      const transformedData = transformData(response?.data || [], attributes)
      setProductList(transformedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length === 0) return

    const productAttributes = data.map(item => {
      const entityAttribute = item.attributes.find(attr => attr.entity === 'PRODUCT_ID')

      return {
        id: item.id,
        entity: entityAttribute?.entity || '',
        endpoint: productEntities.find(({ value }) => value === 'PRODUCT_ID')?.endpoint || '',
        attribute: {
          value: entityAttribute?.ids?.[0] || '',
        },
      }
    })

    if (productAttributes.length > 0) {
      fetchProducts(productAttributes, productAttributes[0].endpoint)
    }
  }, [data])

  return (
    <Table
      view={'actions'}
      title={value}
      className={className}
      search={true}
      renderSearchValue={(searchValue) => {
        const filteredProducts = productList.filter(
          el => el.attribute.label.toLowerCase().includes(searchValue.toLowerCase())
            || el.attribute.value.toLowerCase().includes(searchValue.toLowerCase())
        );

        return (
          <>
            <Head view={`actions.${value}`} columns={[{ value: 'article' }, { value: 'product' }]} />
            <Body>
              {loading ? (
                <Row>
                  <Cell>
                    <ViewLoader />
                  </Cell>
                </Row>
              ) : filteredProducts.length > 0 ? (
                filteredProducts.map(({ id, attribute }) => (
                  <Row key={id}>
                    <Cell>{attribute.value}</Cell>
                    <Cell bold>{attribute.label}</Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={2} style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                    <EmptyState />
                  </Cell>
                </Row>
              )}
            </Body>
          </>
        );
      }}
    />
  )
}

export default ProductBonus
