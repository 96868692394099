// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Discount_isOpen__2w3qb > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.Discount_details-header__3quHY{\n  border-top: 1px solid var(--Gris-20, #CCD1E4)!important;\n  border-bottom: 1px solid var(--Gris-20, #CCD1E4);\n  background: #ECFEFC;\n  color: var(--Gris-100, #68688C);\n  font-family: Inter;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 21px; /* 175% */\n  letter-spacing: 0.24px;\n}", ""]);
// Exports
exports.locals = {
	"isOpen": "Discount_isOpen__2w3qb",
	"details-header": "Discount_details-header__3quHY"
};
module.exports = exports;
