// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Head_thead__3zrVx {\n  border-bottom: 1px solid #ccd1e4;\n}\n\n.Head_th__23DSq {\n  padding: 16px 24px;\n}\n\n.Head_span__1AzUY {\n  max-width: 100%;\n  text-align: start;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font: normal 500 14px/150% Inter;\n  text-transform: uppercase;\n  color: #68688c;\n}\n\n\n.Head_viewExplainHead__1NQtB {\n  display: flex;\n  grid-gap: 1rem;\n  gap: 1rem;\n  align-items: center;\n}\n\n.Head_viewExplainHead__1NQtB span{\n  min-width: -webkit-fit-content;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n}", ""]);
// Exports
exports.locals = {
	"thead": "Head_thead__3zrVx",
	"th": "Head_th__23DSq",
	"span": "Head_span__1AzUY",
	"viewExplainHead": "Head_viewExplainHead__1NQtB"
};
module.exports = exports;
