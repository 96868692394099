// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".emptyState_emptyState__3C_Lh{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    grid-gap: 2ch;\n    gap: 2ch;\n    padding-bottom: 1rem;\n    color: #68688C;\n    font-weight: 500;\n    font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"emptyState": "emptyState_emptyState__3C_Lh"
};
module.exports = exports;
