// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewLoader_loader__176FZ {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 20px 24px;\n  grid-gap: 5px;\n  gap: 5px;\n}\n\n.ViewLoader_small__1sC2h {\n  padding: 7px 0px;\n}\n\n.ViewLoader_loader__176FZ > span {\n  width: 7px;\n  height: 7px;\n  border-radius: 50%;\n  background: var(--primary-color);\n  animation: ViewLoader_bounce__2KGGL 1.5s infinite ease-in-out;\n}\n\n.ViewLoader_loader__176FZ > span:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.ViewLoader_loader__176FZ > span:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes ViewLoader_bounce__2KGGL {\n  0%,\n  80%,\n  100% {\n    transform: scale(0.6);\n    opacity: 0.5;\n  }\n  40% {\n    transform: scale(1);\n    opacity: 1;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"loader": "ViewLoader_loader__176FZ",
	"small": "ViewLoader_small__1sC2h",
	"bounce": "ViewLoader_bounce__2KGGL"
};
module.exports = exports;
