// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Cell_td__e78vK {\n  padding: 16px 24px;\n}\n\n.Cell_container__2AqlI {\n  max-width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.Cell_span__3eSFD {\n  text-align: start;\n  font: normal 600 14px/150% Inter;\n  color: var(--Gris-100, #68688c);\n}\n\n.Cell_bold__1zf73 {\n  color: var(--Gris-200, #303048);\n}\n", ""]);
// Exports
exports.locals = {
	"td": "Cell_td__e78vK",
	"container": "Cell_container__2AqlI",
	"span": "Cell_span__3eSFD",
	"bold": "Cell_bold__1zf73"
};
module.exports = exports;
