export const EVERYWHERE = 'EVERYWHERE'

const ALL = "Todos"

export const PERU_COLUMNS = ['department', 'province', 'city', 'branch', 'pos']

export const ARG_COLUMNS = ['branch', 'pos']

const findParentCompany = (node, targetId, parentCompany = null) => {
    if (!node) return null;
    
    if (node.id.startsWith("emp_") && node.id.split("_").length <= 2) {
        parentCompany = { id: node.id, label: node.description };
    }

    if (node.id === targetId) {
        return parentCompany;
    }

    if (node.childs) {
        for (const child of node.childs) {
            const result = findParentCompany(child, targetId, parentCompany);
            if (result) return result;
        }
    }

    return null;
};


const splitHierarchy = (str) => {
    const parts = str.split('_');
    const result = [];

    for (let i = 0; i < parts.length; i += 2) {
        result.push(parts.slice(0, i + 2).join('_'));
    }

    return result;
};

export const findCompanies = (data, origins) => {
    const parents = []
    origins.forEach(whereId => {
        if (whereId === EVERYWHERE) {
            const allCompanies = data.childs.map(child => {
                return { id: child.id, label: child.description }
            })
            parents.push(...allCompanies)
        } else if (whereId.includes('emp')) {
            const splitted = splitHierarchy(whereId)
            const item = data.childs.find(com => com.id === splitted[0])
            !parents.some(par => par.id === item.id) && parents.push({ id: item.id, label: item.description })
        } else {
            console.log(data, whereId);
            const parentCompany = findParentCompany(data, whereId);
            console.log(parentCompany)
            if (parentCompany) {
                !parents.some(par => par.id === parentCompany.id) && parents.push(parentCompany);
            }
        }
    });

    return parents
}


export const getMatrix = (data, origins, isPeru) => {
    const matrix = [];
    buildMatrix(data, [], matrix, origins, isPeru);
    return matrix;
};


const buildMatrix = (node, path, matrix, origins, isPeru) => {
    const newPath = [...path, node.description];

    const max = isPeru ? 6 : 3

    if (origins.includes(node.id)) {
        while (newPath.length < max) {
            newPath.push(ALL);
        }
        matrix.push(newPath);
    }

    if (node.childs) {
        for (const child of node.childs) {
            buildMatrix(child, newPath, matrix, origins, isPeru);
        }
    }
};

export const removeRepeated = (arr) => {
    return arr.map((row, i, array) =>
        row.map((value, j) => (i > 0 && value === array[i - 1][j] && value !== ALL ? "" : value))
    );
}

export const getColAmount = (origins, matrix, data, isPeru) => {
    const MAX_COLUMNS = isPeru ? 6 : 3;
    let colAmount = new Array(MAX_COLUMNS).fill(0);
    const wantedCompany = data.childs.find(emp => emp.description === matrix[0][1])
    const parentLocations = origins.map(o => searchTree(wantedCompany, o, matchId))
    var allLocations = [];
    parentLocations.forEach(function (o) {
        return getAllChildren(o, allLocations);
    });
    const allIds = getAllSelectedIds(wantedCompany, allLocations)
    getCount(wantedCompany, 0, colAmount, allIds)
    return colAmount.slice(1, colAmount.length)
}


const findByDescription = (description, tree) => {
    if (tree?.description === description) return tree

    if (tree.childs && tree.childs.length) {
        for (const child of tree.childs) {
            const found = findByDescription(description, child);
            if (found) {
                return found;
            }
        }
    }
}


const matchId = (element, id) => {
    return element.id === id
}


const getAllChildren = (parent, allChildren) => {
    allChildren.push(parent?.id)
    if (parent?.hasOwnProperty('childs')) {
        parent.childs.forEach(c => getAllChildren(c, allChildren))
    }
}

const searchTree = (element, matchingParam, comparison, results = null) => {
    if (comparison(element, matchingParam)) {
        if (results === null) {
            return element
        }
        results.push(element)
    } else if (element.childs != null) {
        let i
        let result = null
        for (i = 0; result == null && i < element.childs.length; i++) {
            result = searchTree(element.childs[i], matchingParam, comparison, results)
        }
        return result
    }
    return null
}


function getAllSelectedIds(tree, selectedIds) {
    const selectedSet = new Set(selectedIds);
    const completedSet = new Set(selectedIds);


    function traverse(node, path = []) {
        const currentPath = [...path, node.id];
        const hasSelectedChild = node.childs?.some(child => selectedSet.has(child.id));

        if (selectedSet.has(node.id) || hasSelectedChild) {
            for (const id of currentPath) {
                completedSet.add(id);
            }
        }

        if (node.childs) {
            for (const child of node.childs) {
                traverse(child, currentPath);
            }
        }
    }

    traverse(tree);
    return Array.from(completedSet);
}

const getCount = (node, j, list, origins) => {
    if (origins.includes(node.id)) {
            list[j] = list[j] + 1;
      }
        if(node.childs){
          const Jinicial = j + 1;
          for (let k = 0; k < node.childs.length; k++) {
            const { list: listAux } = getCount(node.childs[k], Jinicial, list, origins);
            list = listAux;
          }
    
        }
      return {list}
} 