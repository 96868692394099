import React from 'react'

import style from './ViewLoader.module.css'

const ViewLoader = ({ small = false }) => {
  return (
    <div className={`${style.loader} ${small !== false ? style.small : ''}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default ViewLoader
