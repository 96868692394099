import React from 'react'

import styles from './ViewTag.module.css'

const ViewTag = ({ label, color, borderColor, fontColor, className, unfilled = false }) => {
  const inlineStyles = {}
  if (color) {
    inlineStyles['--tag-color'] = color
  }
  if (borderColor) {
    inlineStyles['--border-color'] = borderColor
  }
  if (fontColor) {
    inlineStyles['--font-color'] = fontColor
  }

  return (
    <div className={`${styles.tag} ${className ?? ''} ${unfilled ? styles.unfilled : ''}`} style={inlineStyles}>
      <div></div>
      <span className={styles.span}>{label}</span>
    </div>
  )
}

export default ViewTag
