import React, { useState } from 'react'
import './css/PromotionRow.css'
import StatusBadge from '../common/StatusBadge/StatusBadge'
import { SortableHandle } from 'react-sortable-hoc'
import Switch from 'react-switch'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import imgCopy from './assets/faCopy.svg'
import imgDelet from './assets/icono-borrar.svg'
import imgEdit from './assets/icono-modificar.svg'
import imgGripLines from './assets/GripLines.svg'

const DragHandle = SortableHandle(() => (
  <div className="DragHandle-div">
    <img src={imgGripLines} alt="griplines.svg" />
  </div>
))

const PromotionRow = props => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const status =
    moment().isAfter(moment(props.date_time_to)) && !props.always_current
      ? 'expired'
      : props.paused
      ? 'paused'
      : 'active'

  const onTogglePause = () => {
    setLoading(true)
    props
      .onTogglePause(props.id)
      .then(() => {
        setLoading(false)
      })
      .catch(err => console.log(err))
  }

  const onDelete = e => {
    e.preventDefault()
    setDeleting(true)
    props
      .onDeletePromo(props.id)
      .then(() => {
        setDeleting(false)
      })
      .catch(err => console.log(err))
  }

  const onEditClick = e => {
    e.preventDefault()
    history.push({
      pathname: '/crear-promocion',
      state: { promoId: props.id, copy: false },
    })
  }

  const onCopyClick = e => {
    e.preventDefault()
    history.push({
      pathname: '/crear-promocion',
      state: { promoId: props.id, copy: true },
    })
  }

  const goToView = e => {
    e.preventDefault()
    document.querySelector(".wrapper").scrollTo(0, 0)
    history.push({
      pathname: '/Promotion',
      state: { promoId: props.id, copy: false },
    })
  }

  return (
    <tr className={`${props.className} ${deleting ? 'disabled' : ''} promotion-table-row`} onClick={e => goToView(e)}>
      <td className="code">{props.code}</td>
      <td className="name">{props.name}</td>
      <td>
        <StatusBadge status={status} pill outline />
      </td>
      <td onClick={e => e.stopPropagation()}>
        <Switch
          checked={!props.paused}
          onChange={onTogglePause}
          onColor="#FDC792"
          onHandleColor="#FF7D00"
          disabled={loading || status === 'expired'}
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={13}
          width={30}
          className={`react-switch toggle-pausar ${props.paused ? 'isPause' : null}`}
        />
      </td>
      <td className="actions ">
        <div className="flex-aling">
          <div
            className="action "
            onClick={e => {
              e.stopPropagation()
              onEditClick(e)
            }}
            title={t('actions.edit')}>
            <img src={imgEdit} alt="edit.svg" />
          </div>

          <div
            className="action "
            onClick={e => {
              e.stopPropagation()
              onDelete(e)
            }}
            title={t('actions.delete')}>
            <img src={imgDelet} alt="delet.svg" />
          </div>

          <div
            className="action "
            onClick={e => {
              e.stopPropagation()
              onCopyClick(e)
            }}
            title={t('actions.copy')}>
            <img src={imgCopy} alt="copy.svg" />
          </div>
        </div>
      </td>
      <td
        className={`DragHandle ${props.searchQuery && props.searchQuery.length !== 0 ? 'isSearchingActive' : ''} ${
          props.statusFilter && props.statusFilter.length !== 0 ? 'statusFilter' : ''
        }`}>
        {props.sortable && <DragHandle />}
      </td>
    </tr>
  )
}

export default PromotionRow
