// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SetPrice_isOpen__Yt793 > td:last-child > div > button > svg {\n  rotate: 180deg;\n}\n\n.SetPrice_discount__3_zjD,\n.SetPrice_payment__1RPuu {\n  padding-inline: unset;\n  width: 20px;\n}\n\n.SetPrice_emptyStateCell__1QUBX div, .SetPrice_emptyStateCell__1QUBX div span{\n  overflow: visible;\n  text-overflow: unset;\n\n}\n\n.SetPrice_setPriceInner__3RI8Y tr td:last-of-type{\n    width: 36%;\n}\n\n.SetPrice_setPriceTable__1VgF7 thead th:last-of-type, .SetPrice_setPriceTable__1VgF7 tbody td:nth-last-child(2){\n  text-align: right;\n}\n.SetPrice_setPriceTable__1VgF7 tbody td:nth-last-child(2){\n  font-weight: bolder;\n  text-align: right;\n}", ""]);
// Exports
exports.locals = {
	"isOpen": "SetPrice_isOpen__Yt793",
	"discount": "SetPrice_discount__3_zjD",
	"payment": "SetPrice_payment__1RPuu",
	"emptyStateCell": "SetPrice_emptyStateCell__1QUBX",
	"setPriceInner": "SetPrice_setPriceInner__3RI8Y",
	"setPriceTable": "SetPrice_setPriceTable__1VgF7"
};
module.exports = exports;
