// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ViewCard_card__cZyf4 {\n  padding: 24px 18px;\n  background: var(--bg-color, #ecfefc);\n  border-radius: 8px;\n  box-shadow: 1px 1px 1px 0px rgba(104, 104, 140, 0.04), 0px 2px 5px 0px rgba(104, 104, 140, 0.17);\n}\n", ""]);
// Exports
exports.locals = {
	"card": "ViewCard_card__cZyf4"
};
module.exports = exports;
