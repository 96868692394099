import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import style from './ViewTable.module.css'
import { ViewSearch } from '..'

const ViewTable = ({ children, entity, className, view, title, search = true, renderSearchValue }) => {
  const { t } = useTranslation('viewPromo')
  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = (value) => {
    setSearchValue(value)
  }

  return (
    <div className={`${style.container} ${className ?? ''}`}>
      <div className={style['container-header']}>
        <div>
          {entity && (
            <h3 className={style.entity}>
              <strong>Entidad</strong>
              {entity}
            </h3>
          )}
          {view && title && (
            <h3 className={style.entity}>
              <strong>{t(`${view}.type.${title}`)}</strong>
            </h3>
          )}
        </div>
        {search && (
          <ViewSearch
            view={`${entity !== undefined ? view + '-' + entity : title !== undefined ? view + '-' + title : view}`}
            setSearchValueProp={handleSearchChange}
          />
        )}
      </div>
      {renderSearchValue && (
        <table className={style.table}>
          {renderSearchValue(searchValue)}
        </table>
      )}
      <table className={style.table}>{children}</table>
    </div>
  )
}

export default ViewTable
